import React from 'react'
import { useIntl } from 'react-intl'
import AddIcon from '@material-ui/icons/Add'

import { ThemeButton, useModalContext } from '../../../../ui'
import { AddressDialog } from './address-dialog.component'
import { useInspectionsContext } from '../../contexts'

export const AddressInputBtn = ({ onSelect }) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { addresses } = useInspectionsContext()

  const handleOpenAddressModal = () => {
    open({
      Component: AddressDialog,
      data: { onSelect },
      type: 'responsive',
      maxWidth: 'md',
    })
  }

  // React.useEffect(() => handleOpenAddressModal(), [])

  return (
    <ThemeButton
      variant="outlined"
      color="inherit"
      labelM="sm"
      startIcon={addresses.length ? <AddIcon /> : null}
      onClick={handleOpenAddressModal}
    >
      {formatMessage({
        id: `pages.inspections.${addresses.length ? 'addNewAddressBtn' : 'addAddressBtn'}.label`,
      })}
    </ThemeButton>
  )
}
