import { Box, Checkbox, FormHelperText, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAjaxContext } from '../../../../core/ajax'
// import { useConsultContext } from '../../../consult/contexts'
import { SelectInput, TextInput, ThemeButton } from '../../../../ui'
import { CampaignPanel, CampaignTable, CompatibleCampaignTable } from '../../../campaigns'
import { Alert } from '@material-ui/lab'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useHistory, useLocation } from 'react-router-dom'
import { parse } from 'qs'
//import ArrowCircleLeftOutlined from '@material-ui/icons/ArrowCircleLeftOutlined'

export const CertificateServices = ({
  classes,
  formState,
  combos,
  handleChange,
  showErrors,
  errors,
  viewCampanas = true,
}) => {
  const { formatMessage } = useIntl()
  const { get } = useAjaxContext()
  const location = useLocation()
  const history = useHistory()
  const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
  const servicesStrigified = formState['services']?.join(',')
  // const { setCurrentCampana, empresa } = useConsultContext()
  const [financiacion, setFinanciacion] = useState(false)
  const [campanas, setCampanas] = useState()
  const [campanasTable, setCampanasTable] = useState()
  const [campana, setCampana] = useState()
  const [errorIban, setErrorIban] = useState(true)
  const [applieCampana, setApplieCampana] = useState()
  const [maxIndex, setMaxIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const [campanasCompatibles, setCampanasCompatibles] = useState([])
  const [hideApply, setHideApply] = useState(
    !formState.origen ? false : formState.origen !== 'instalador' ? true : false
  )
  const [pagination, setPagination] = useState({
    total_entries: 0,
    loading: false,
  })
  const [paginationCompatibles, setPaginationCompatibles] = useState({
    total_entries: 0,
    loading: false,
  })
  let origen = null
  formState.origen !== undefined
    ? (origen = formState.origen)
    : window.sessionStorage.getItem('origen') === 'undefined'
    ? (origen = 'instalador')
    : (origen = window.sessionStorage.getItem('origen'))
  const updateCampana = (financiacion, data) => {
    if (financiacion && data.campana_financiacion) {
      setCampana(data.campana_financiacion)
      handleChange({ target: { name: 'financiacion', value: true } })
      // setCurrentCampana(data.campana_financiacion) //Set current campana on context to create requiered array
    } else {
      setCampana(data?.campana)
      handleChange({ target: { name: 'financiacion', value: false } })
      // setCurrentCampana(data?.campana) //Set current campana on context to create requiered array
    }
  }

  const applyCampaign = (event) => {
    const { name, checked } = event.target
    handleChange({ target: { name: name, value: checked } })
    if (checked) {
      //Apply campaign
      if (formState['financiacion']) {
        handleChange({
          target: {
            name: 'camp1',
            value: formState['campanas'].campana_financiacion.codigo,
          },
        })
      } else {
        handleChange({
          target: {
            name: 'camp1',
            value: formState['campanas'] ? formState['campanas']?.campana?.codigo : null,
          },
        })
      }
    } else {
      //Reseteamos el "camp1" cada vez que desmarcamos el check para aplicar la campaña
      handleChange({
        target: {
          name: 'camp1',
          value: undefined,
        },
      })
    }
  }
  const updateFinanciacion = () => {
    applyCampaign({ target: { name: 'applyCampaign', checked: false } })
    handleChange({ target: { name: 'iban', value: '' } })
    setFinanciacion((financiacion) => {
      updateCampana(!financiacion, campanas[index])
      return !financiacion
    })
  }

  const isValidIban = (iban) => {
    const regex =
      /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/
    return regex.test(iban)
  }

  const updateIban = (event) => {
    if (!isValidIban(event.target.value)) {
      setErrorIban(true)
    } else {
      setErrorIban(false)
    }
    handleChange({ target: { name: 'iban', value: event.target.value } })
  }
  const handleSetCampaignsTable = (campanas) => {
    const formattedCampaingsTable = campanas.map((campana) => campana.campana)
    setCampanasTable(formattedCampaingsTable)
  }

  useEffect(() => {
    updateCampaignsTable()
  }, [servicesStrigified, index]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateCampaignsTable = (page = 1, pageSize = 5) => {
    //Reseteamos el "camp1" cada vez que cambiamos el selector: "Servicio a instalar"
    //para evitar que se envíe si ya hemos seleccionado una campaña y después se cambian los servicios desde este selector
    handleChange({
      target: {
        name: 'camp1',
        value: undefined,
      },
    })
    handleChange({ target: { name: 'origen', value: origen } })
    if (!formState['cidi'] || !formState['services']) return
    setPagination({ ...pagination, loading: true })
    const params = {
      empresa_ca_id: formState.empresa_ca_id,
      origen: origen,
      ...DEFAULT_QUERY,
      _pagina: page.toString(),
      _num_x_pagina: pageSize.toString(),
      ...(formState.potencia && { potencia: formState.potencia }),
      ...(formState.tipo_mercado && { mercado: formState.tipo_mercado }),
      ...(formState.tipo_gas && { tipo_gas: formState.tipo_gas }),
    }
    formState['services'].map((el) => (params[el] = true))
    get(`/campana/by_cidi/${formState['cidi']}`, { params }).then(({ data }) => {
      handleSetCampaignsTable(data.campanas)
      setMaxIndex(data.campanas?.length ? data.campanas.length : 0)
      applyCampaign({ target: { name: 'applyCampaign', checked: hideApply ? true : false } })
      setApplieCampana(data.applies_empresa)
      handleChange({ target: { name: 'applieCampana', value: data.applies_empresa } })
      setCampanas(data.campanas[index])
      setFinanciacion(false)

      updateCampana(false, data.campanas[index])
      data.campanas.length <= 0
        ? handleChange({ target: { name: 'campanas', value: null } })
        : handleChange({ target: { name: 'campanas', value: data.campanas[index] } })
      setPagination({ ...pagination, loading: false, total_entries: data.total_registros })
    })
  }

  const handleChangeCampaigns = (page, pageSize) => {
    updateCampaignsTable(page, pageSize)
  }

  const updateCompatibleCampaignsTable = () => {
    if (formState.camp1) {
      const params = {
        ...DEFAULT_QUERY,
      }
      setPaginationCompatibles({ ...paginationCompatibles, loading: true })
      get(
        `/campana/tabla_campana_compatibles/${formState.camp1.id}?empresa_ca_id=${formState['empresa_ca_id']}`,
        { params }
      ).then(({ data }) => {
        setPaginationCompatibles({
          ...paginationCompatibles,
          loading: false,
          total_entries: data.total_registros,
        })
        setCampanasCompatibles(data)
      })
    } else {
      setCampanasCompatibles()
      if (formState.camp2) {
        handleChange({
          target: {
            name: 'camp2',
            value: undefined,
          },
        })
      }
      if (formState.camp3) {
        handleChange({
          target: {
            name: 'camp3',
            value: undefined,
          },
        })
      }
    }
  }

  useEffect(() => {
    updateCompatibleCampaignsTable()
  }, [formState.camp1]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdvance = () => {
    //Reseteamos el "camp1" cada vez nos movemos entre campañas
    //para evitar que se éste envíe si ya se ha seleccionado una campaña y después se cambia a otra campaña
    handleChange({
      target: {
        name: 'camp1',
        value: undefined,
      },
    })
    setIndex(index + 1)
  }

  const handleRetraso = () => {
    //Reseteamos el "camp1" cada vez nos movemos entre campañas
    //para evitar que se éste envíe si ya se ha seleccionado una campaña y después se cambia a otra campaña
    handleChange({
      target: {
        name: 'camp1',
        value: undefined,
      },
    })
    setIndex(index - 1)
  }

  return (
    <Box>
      <Typography variant="body1" color="primary" paragraph>
        {formatMessage({ id: 'pages.certificates.services.title' })}
      </Typography>
      <Box marginBottom={3}>
        <Box display="flex" alignItems="center" className={classes.container}>
          <Box display="flex" flexDirection="column">
            <Box flexDirection="column" mb={5}>
              <SelectInput
                className={classes.input}
                required
                name="services"
                value={formState['services']}
                values={combos?.productos_instalacion?.data || []}
                onChange={handleChange}
                size="small"
                multiple={true}
              />
            </Box>
            <Box>
              {viewCampanas && campana && campanasTable && !formState.cod_solicitud_zeus ? (
                applieCampana ? (
                  <Box>
                    {/* {!hideApply ? (
                      !formState.applyCampaign ? (
                        <Box marginBottom="1em">
                          <Alert severity="warning">
                            {formatMessage({
                              id: 'pages.certificates.services.apply.campaing.info',
                            })}
                          </Alert>
                        </Box>
                      ) : null
                    ) : null}
                    <CampaignPanel
                      formState={campana}
                      isFinanceCampaign={financiacion}
                      index={index}
                      setIndex={setIndex}
                      maxIndex={maxIndex}
                    />
                    {!hideApply && (
                      <Checkbox
                        name="applyCampaign"
                        checked={formState.applyCampaign ? formState.applyCampaign : false}
                        onChange={applyCampaign}
                      />
                    )}
                    {!hideApply &&
                      formatMessage({ id: 'pages.certificates.services.apply.campaing' })} */}
                    {campanasTable && (
                      <Box mb={5}>
                        <Typography variant="h6" gutterBottom>
                          {formatMessage({
                            id: 'pages.certificates.services.select.campaing.title',
                          })}
                        </Typography>
                        <Typography gutterBottom>
                          {formatMessage({
                            id: 'pages.certificates.services.select.campaing',
                          })}
                        </Typography>
                        <CampaignTable
                          campanas={campanasTable}
                          handleChange={handleChange}
                          formState={formState}
                          pagination={pagination}
                          handleChangePagination={handleChangeCampaigns}
                        />
                      </Box>
                    )}
                    {campanasCompatibles && (
                      <Box mb={5}>
                        <Typography variant="h6" gutterBottom>
                          {formatMessage({
                            id: 'pages.certificates.services.select.compatible.campaing.title',
                          })}
                        </Typography>
                        <Typography gutterBottom>
                          {formatMessage({
                            id: 'pages.certificates.services.select.compatible.campaing',
                          })}
                        </Typography>
                        <CompatibleCampaignTable
                          campanas={campanasCompatibles.campanas}
                          handleChange={handleChange}
                          formState={formState}
                          pagination={paginationCompatibles}
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box ml={1} display="flex" justifyContent="flex-end">
                    <Alert severity="warning">
                      {formatMessage({
                        id: 'pages.certificates.services.noapply.warning',
                      })}
                    </Alert>
                  </Box>
                )
              ) : null}
              {viewCampanas && campanas?.campana_financiacion ? (
                <Box display="flex" alignItems="center" mt={2}>
                  <Typography variant="body2">
                    {formatMessage({ id: 'pages.certificates.services.finance' })}
                  </Typography>
                  <Checkbox
                    color="primary"
                    size="small"
                    name="financiacion"
                    onClick={() => {
                      updateFinanciacion()
                    }}
                  />
                </Box>
              ) : null}
              {(!financiacion &&
                formState['applyCampaign'] &&
                campanas?.campana?.ingreso &&
                applieCampana) ||
              (hideApply &&
                !financiacion &&
                campanas?.campana?.ingreso &&
                applieCampana &&
                !formState.cod_solicitud_zeus) ? (
                <Box /*display="flex" flexDirection="column"*/ mt={2} mb={2}>
                  <Typography variant="body1" color="primary" paragraph>
                    {formatMessage({ id: 'pages.certificates.services.iban.title' })}
                  </Typography>
                  <TextInput
                    required={formState['applyCampaign'] && !formState['financiacion']}
                    name="iban"
                    inputProps={{
                      pattern:
                        '(?:(?:IT|SM)\\d{2}[A-Z]\\d{22}|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$',
                    }}
                    value={formState['iban']}
                    onChange={updateIban}
                  />
                  <FormHelperText error={(showErrors && errors['iban']) || errorIban}>
                    {errorIban &&
                      formatMessage({
                        id: 'pages.certificates.iban.error.text',
                      })}
                  </FormHelperText>
                  <Typography variant="body1" color="primary" paragraph>
                    {formatMessage({ id: 'pages.certificates.services.iban.owner.title' })}
                  </Typography>
                  <TextInput
                    required={
                      (formState['applyCampaign'] && !formState['financiacion']) ||
                      (hideApply && !financiacion && campanas?.campana?.ingreso)
                    }
                    name="titular_iban"
                    value={formState['titular_iban']}
                    onChange={handleChange}
                  />
                  <FormHelperText error={(showErrors && errors['ibanOwner']) || true}>
                    {errors['titular_iban'] &&
                      formatMessage({
                        id: 'pages.certificates.iban.owner.error.text',
                      })}
                  </FormHelperText>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        <FormHelperText error={showErrors && errors['services']}>
          {showErrors && errors['services']
            ? formatMessage({ id: 'pages.certificates.services.error.text' })
            : null}
        </FormHelperText>
      </Box>
    </Box>
  )
}

export default CertificateServices
