import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@material-ui/core'

import { ADMIN_ROLES } from '../../../../core/constants'
import { FileUploaderInput } from '../../../../ui'
import { useCombos } from '../../../combos'
import { COMPANY_FORM_CONFIG } from '../../constants'
import { useCompanyProfileContext } from '../../contexts'

export const CompanyDocuments = ({ isPrimaryCompany = true }) => {
  const { formState, changeFormState } = useCompanyProfileContext()
  const intl = useIntl()
  const combos = useCombos([])
  const global = useSelector((state) => state.global)
  const isAdmin = ADMIN_ROLES[global.role]
  const inputProps = { formState, onChange: changeFormState, combos, intl }

  return (
    <Box>
      <Typography color="primary" variant={'h6'}>
        {'Documentos de la Empresa'}
      </Typography>
      <Box mt={2}>
        <Grid container spacing={3}>
          {isPrimaryCompany ? (
            <>
              {formState.grabadora ? (
                <Grid item xs={12} sm={6}>
                  <FileUploaderInput
                    {...{
                      ...COMPANY_FORM_CONFIG(inputProps).IBAN_CERTIFICADO,
                      readOnly: !isAdmin,
                    }}
                  />
                </Grid>
              ) : null}
              {formState.captadora ? (
                <Grid item sx={12} sm={6}>
                  <FileUploaderInput
                    {...{
                      ...COMPANY_FORM_CONFIG(inputProps).NIF_EMPRESA,
                      readOnly: !isAdmin,
                    }}
                  />
                </Grid>
              ) : null}
              {formState.captadora ? (
                <Grid item sx={12} sm={6}>
                  <FileUploaderInput
                    {...{
                      ...COMPANY_FORM_CONFIG(inputProps).PERSONA_FISICA,
                      readOnly: !isAdmin,
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item sx={12} sm={6}>
                <FileUploaderInput
                  {...{
                    ...COMPANY_FORM_CONFIG(inputProps).REGISTRO_INDUSTRIAL,
                    readOnly: !isAdmin,
                  }}
                />
              </Grid>
              {formState.captadora ? (
                <Grid item sx={12} sm={6}>
                  <FileUploaderInput
                    {...{
                      ...COMPANY_FORM_CONFIG(inputProps).FORMULARIO_RGPD,
                      readOnly: !isAdmin,
                    }}
                  />
                  <Box mt={1}>
                    <Typography variant="body2" display="inline">
                      {'Descargar '}
                      <a
                        style={{ color: '#004a6b', fontSize: 14 }}
                        href={
                          'https://clicktime.symantec.com/3TUjR9H4pwUrxRnfhzuCY2x6Gi?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F12%2FCUESTIONARIOPRIVACIDADEVALUACIONDEPROVEEDORES.xlsx'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Formulario RGPD
                      </a>
                      {', '}
                      <a
                        style={{ color: '#004a6b', fontSize: 14 }}
                        href={
                          'https://clicktime.symantec.com/3NXiquhwyStBazkz93jyAiz6Gi?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F12%2FInstrucciones-cumplimentacion-Cuestionario-Evaluacion-Proveedores.pdf'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Guía de cumplimentación cuestionario RGPD
                      </a>
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
            </>
          ) : (
            <Grid item sx={12} sm={6}>
              <FileUploaderInput
                {...{
                  ...COMPANY_FORM_CONFIG(inputProps).CERTIFICADO_BANCARIO_IBAN_SWIFT,
                  readOnly: !isAdmin,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
