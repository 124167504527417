import { ROLES } from '../core/constants'

export const COMBOS_MOCK = {
  tipo_documento: {
    data: [
      {
        key: '01',
        value: 'DNI',
      },
      {
        key: '02',
        value: 'NIE',
      },
    ],
    isLoading: false,
  },

  rol_captador: {
    data: [
      {
        key: 'instalador',
        value: 'Visualización Captación',
        description: ROLES.INSTALADOR_CAPTADOR.DESC,
      },
      {
        key: 'administrador',
        value: 'Gestión Captación',
        description: ROLES.ADMINISTRADOR_CAPTADOR.DESC,
      },
    ],
    isLoading: false,
  },
  rol_captador_grabador: {
    data: [
      {
        key: 'instalador',
        value: 'Visualización Captación',
        description: ROLES.INSTALADOR_CAPTADOR.DESC,
      },
      {
        key: 'administrador',
        value: 'Gestión Captación',
        description: ROLES.ADMINISTRADOR_CAPTADOR.DESC,
      },
      {
        key: 'administrador_grabador',
        value: 'Gestión Inspecciones',
        description: ROLES.ADMINISTRADOR_GRABADOR.DESC,
      },
      {
        key: 'admin_captador_grabador',
        value: 'Gestión Captación e Inspecciones',
        description: ROLES.ADMINISTRADOR_CAPTADOR_GRABADOR.DESC,
      },
    ],
    isLoading: false,
  },
  tipo_actividad: {
    data: [
      { key: '01', value: 'Instalador' },
      { key: '02', value: 'Gremio' },
      { key: '03', value: 'Comercializadora' },
    ],
    isLoading: false,
  },
  binary_values: {
    data: [
      { key: '01', value: 'Sí' },
      { key: '00', value: 'No' },
    ],
    isLoading: false,
  },
  ip_installer_categories: {
    data: [
      { key: 'A', value: 'A' },
      { key: 'B', value: 'B' },
      { key: 'C', value: 'C' },
    ],
    isLoading: false,
  },
  estados_iban: {
    data: [
      { key: '10', value: 'Pte. informar' },
      { key: '15', value: 'Pte. validar' },
      { key: '20', value: 'Validado' },
      { key: '01', value: 'Rechazado' },
    ],
    isLoading: false,
  },
  estados_peticion_op: {
    data: [
      { key: '10', value: 'Creada' },
      { key: '20', value: 'En trámite' },
      { key: '30', value: 'En trámite' },
      { key: '40', value: 'En trámite' },
      { key: '50', value: 'En trámite' },
      { key: '60', value: 'Tramitada' },
      { key: '00', value: 'Rechazada' },
      { key: '01', value: 'Caducada' },
      { key: '02', value: 'Anulada' },
    ],
  },
  /*rangos_potencia: {
    data: [
      {
        key: '<50',
        value: 'Menos de 50 kW',
        consumo: 'Menos de 50 kW',
      },
      {
        key: '50-69',
        value: '50 - 69 kW',
        consumo: '50 - 69 kW',
      },
      {
        key: '70-139',
        value: '70 - 139 kW',
        consumo: '70 - 139 kW',
      },
      {
        key: '>140',
        value: 'M\u00e1s de 140 kW',
        consumo: 'M\u00e1s de 140 kW',
      },
    ],
    isLoading: false,
  },
  rangos_consumo: {
    data: [
      {
        key: '<4999',
        value: 'Tarifa RL01 - Menos de 5000 Kwh',
        tarifa_aplicada: 'RL01',
      },
      {
        key: '5000-14999',
        value: 'Tarifa RL02 - 5000 - 14999 kWh',
        tarifa_aplicada: 'RL02',
      },
      {
        key: '15000-49999',
        value: 'Tarifa RL03 - 15000 - 49999 Kwh',
        tarifa_aplicada: 'RL03',
      },
      {
        key: '50000-99999',
        value: 'Tarifa RL04 - 50000 - 100000 Kwh',
        tarifa_aplicada: 'RL04',
      },
      {
        key: '>100000',
        value: 'Tarifa RL04 - M\u00e1s de 100000 kWh',
        tarifa_aplicada: 'RL04',
      },
    ],
    isLoading: false,
  },
  tarifa_aplicada: {
    data: [
      {
        key: 'RL01',
        value: 'RL01',
      },
      {
        key: 'RL02',
        value: 'RL02',
      },
      {
        key: 'RL03',
        value: 'RL03',
      },
      {
        key: 'RL04',
        value: 'RL04',
      },
    ],
    isLoading: false,
  }, */
  tipo_instalacion_ab: {
    data: [
      {
        key: 'IRI',
        value: 'IRI',
      },
      {
        key: 'IRC',
        value: 'IRC',
      },
      {
        key: 'IND',
        value: 'IND',
      },
    ],
    isLoading: false,
  },
  tipo_instalacion_c: {
    data: [
      {
        key: 'IRI',
        value: 'IRI',
      },
      {
        key: 'IND',
        value: 'IND',
      },
    ],
    isLoading: false,
  },
  une: {
    data: [
      {
        key: 'UNE 60670 - 2014',
        value: 'UNE 60670 - 2014',
      },
      {
        key: 'UNE 60620 - 2005',
        value: 'UNE 60620 - 2005',
      },
    ],
    isLoading: false,
  },
  resultado_inspeccion: {
    data: [
      {
        key: 'Correcto',
        value: 'Correcto',
      },
      {
        key: 'Con anomalías secundarias',
        value: 'Con anomalías secundarias',
      },
      {
        key: 'Con anomalías principales',
        value: 'Con anomalías principales',
      },
      {
        key: 'Con anomalías secundarias y principales',
        value: 'Con anomalías secundarias y principales',
      },
      {
        key: 'No deja cortar',
        value: 'No deja cortar',
      },
    ],
    isLoading: false,
  },
  situacion_servicio: {
    data: [
      {
        key: 'Sin servicio Parcial',
        value: 'Sin servicio Parcial',
      },
      {
        key: 'Sin servicio Total',
        value: 'Sin servicio Total',
      },
      {
        key: 'En servicio',
        value: 'En servicio',
      },
    ],
    isLoading: false,
  },
  situacion_suministro: {
    data: [
      {
        key: 'En servicio',
        value: 'En servicio',
      },
      {
        key: 'Corte parcial',
        value: 'Corte parcial',
      },
      {
        key: 'Corte total',
        value: 'Corte total',
      },
    ],
    isLoading: false,
  },
  estados_ip: {
    data: [
      {
        key: '27',
        value: 'Rechazado por gestor',
      },
      {
        key: '20',
        value: 'Rechazado por fecha de inspección',
      },
      {
        key: '23',
        value: 'Rechazado por criterio de aceptación',
      },
      {
        key: '26',
        value: 'Rechazado',
      },
      {
        key: '24|25',
        value: 'Pendiente Validar',
      },
      {
        key: '40|41|42',
        value: 'Validado',
      },
      {
        key: '15',
        value: 'No conformidad',
      },
    ],
    isLoading: false,
  },
  estados_ip_readonly: {
    data: [
      {
        key: '27',
        value: 'Rechazado por gestor',
      },
      {
        key: '20',
        value: 'Rechazado por fecha de inspección',
      },
      {
        key: '23',
        value: 'Rechazado por criterio de aceptación',
      },
      {
        key: '26',
        value: 'Rechazado',
      },
      {
        key: '24',
        value: 'Pendiente Validar',
      },
      {
        key: '25',
        value: 'Pendiente Validar',
      },
      {
        key: '40',
        value: 'Validado',
      },
      {
        key: '41',
        value: 'Validado',
      },
      {
        key: '42',
        value: 'Validado',
      },
      {
        key: '15',
        value: 'No conformidad',
      },
    ],
    isLoading: false,
  },
  estados_jca: {
    data: [
      {
        key: '30',
        value: 'Pte validar',
      },
      {
        key: '40|41|42',
        value: 'Validado',
      },
      {
        key: '25',
        value: 'Rechazado',
      },
      {
        key: '15',
        value: 'No conformidad',
      },
      {
        key: '39',
        value: 'No conformidad cerrada',
      },
    ],
    isLoading: false,
  },
  estados_jca_readonly: {
    data: [
      {
        key: '30',
        value: 'Pte validar',
      },
      {
        key: '40',
        value: 'Validado',
      },
      {
        key: '41',
        value: 'Validado',
      },
      {
        key: '42',
        value: 'Validado',
      },
      {
        key: '25',
        value: 'Rechazado',
      },
      {
        key: '15',
        value: 'No conformidad',
      },
      {
        key: '39',
        value: 'No conformidad cerrada',
      },
    ],
    isLoading: false,
  },
  resultado_no_conformidad: {
    data: [
      { key: '1', value: 'Validado' },
      { key: '2', value: 'Rechazado' },
      { key: '3', value: 'Devuelto a EEII' },
    ],
    isLoading: false,
  },
  motivos_no_conformidad_jca: {
    data: [
      { key: '1', value: 'Dirección de la instalación o datos del cliente erróneos' },
      {
        key: '2',
        value:
          'Las anomalías mostradas en la web no coinciden con las del Informe de Anomalías/Faltan anomalías en la web que sí se detectaron en la Inspección periódica',
      },
      {
        key: '3',
        value:
          '“Sin anomalías” en la web y el cliente dispone de informe de Anomalías con defectos',
      },
      { key: '4', value: 'Otros' },
    ],
    isLoading: false,
  },
  tipo_dias: {
    data: [
      { key: '1', value: '1 día' },
      { key: '2', value: '2 días' },
      { key: '+2', value: 'Más de 2 días' },
    ],
  },
}
