import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Grid, Chip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { SelectInput, RadioInput, ThemeButton } from '../../../../ui'
import { useCombos } from '../../../combos'
import { useInspectionsContext } from '../../contexts'
import { InstCupsBlock } from './inst-cups-block.component'
import { InstAddressBlock } from './inst-address-block.component'

export const InstallationForm = () => {
  const { formatMessage } = useIntl()
  const { formFields, validateInstallationStep, formState, error, type, company } =
    useInspectionsContext()
  const { loadingHomeInfo, resetAddresses, openNonconformity, addresses } = useInspectionsContext()
  const combos = useCombos([])
  const instTypesByCompanyCategory =
    (company.categoria === 'C'
      ? combos['tipo_instalacion_c']?.data
      : combos['tipo_instalacion_ab']?.data) || []

  const handleSubmit = (e) => {
    e.preventDefault()
    validateInstallationStep()
  }

  useEffect(() => {
    resetAddresses()
  }, [formState.tipo_instalacion]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box p={3} mt={2} bgcolor="grey.200" borderRadius={6}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" color="textSecondary">
          {formatMessage({ id: 'pages.inspections.installation.title' })}
        </Typography>
        <Chip size="small" label={type.toUpperCase()} />
      </Box>
      <Box mt={3}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioInput
                {...{ ...formFields.TIPO_INSTALACION, values: instTypesByCompanyCategory }}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput {...{ ...formFields.UNE }} />
            </Grid>
            {formState.tipo_instalacion === 'IRC' ? <InstAddressBlock /> : <InstCupsBlock />}
          </Grid>
          {error.message && (
            <Box mt={2} mb={1} bgcolor="common.white">
              <Alert severity="error">{error.message}</Alert>
            </Box>
          )}
          <Box display="flex" justifyContent={type === 'jca' ? 'space-between' : 'flex-end'} mt={3}>
            {type === 'jca' ? (
              <ThemeButton
                onClick={openNonconformity}
                variant="outlined"
                style={{ backgroundColor: '#ffffff' }}
                labelM="sm"
                disabled={!addresses.length}
              >
                {formatMessage({ id: 'pages.inspections.nonconformityBtn.label' })}
              </ThemeButton>
            ) : null}
            <ThemeButton type="submit" color="primary" labelM="sm" disabled={loadingHomeInfo}>
              {formatMessage({ id: 'pages.inspections.validateBtn.label' })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
