import React, { useMemo } from 'react'
import { withStyles, TextField, FormControl, FormHelperText, Tooltip } from '@material-ui/core'
import { Icon, Typography, MenuItem } from '@material-ui/core'

import { textInputStyles } from './text-input.styles'

const ROWS_MAX = 5
const DEFAULT_MAX_LENGTH = 250

export const TextInput = React.memo(
  withStyles(textInputStyles)(
    ({ classes, type = 'text', readOnly = false, rowsMax = ROWS_MAX, rows = 1, ...props }) => {
      const { error, errorMessage, ...restProps } = props
      const InputProps = useMemo(() => {
        return { className: classes.input, ...props.InputProps }
      }, [classes.input, props.InputProps])
      const tooltip = useMemo(() => {
        return props.tooltip ? (
          <span style={{ fontSize: 16, marginLeft: 4 }}>
            <Tooltip
              placement="top"
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {props.tooltip}
                </Typography>
              }
            >
              <Icon fontSize="inherit">help_outline</Icon>
            </Tooltip>
          </span>
        ) : null
      }, [props.tooltip])

      const inputPropsDefault = {
        pattern: props.required ? '.*[^ ].*' : null,
        maxLength: DEFAULT_MAX_LENGTH,
      }

      const typographyVariant = props.fontSize === 'small' ? '2' : '1'
      return (
        <FormControl className={classes.root}>
          {props.title ? (
            <Typography variant={`subtitle${typographyVariant}`}>
              {props.title}
              {tooltip ? tooltip : ''}
            </Typography>
          ) : null}

          {readOnly ? (
            <div style={{ display: 'flex' }}>
              <Typography
                variant={`body${typographyVariant}`}
                color="textSecondary"
                style={{ whiteSpace: 'pre-line' }}
              >
                {props.value ? props.value + (props.units ? ' ' + props.units : '') : '--'}
              </Typography>
              {props.tooltipValue ? (
                <span style={{ fontSize: 16, marginLeft: 4 }}>
                  <Tooltip
                    placement="top"
                    title={
                      <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                        {props.tooltipValue}
                      </Typography>
                    }
                  >
                    <Icon fontSize="inherit">help_outline</Icon>
                  </Tooltip>
                </span>
              ) : null}
            </div>
          ) : (
            <>
              <TextField
                maxRows={rowsMax}
                minRows={rows}
                {...restProps}
                type={type}
                variant="outlined"
                value={props.value || ''}
                placeholder={props.placeholder}
                onChange={props.onChange}
                InputProps={InputProps}
                inputProps={{ ...inputPropsDefault, ...props.inputProps }}
              >
                {props.values
                  ? props.values.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
              {(error && errorMessage) || props.helpMessage ? (
                <FormHelperText id="my-helper-text" error={error}>
                  {error ? errorMessage : props.helpMessage}
                </FormHelperText>
              ) : null}
            </>
          )}
        </FormControl>
      )
    }
  )
)
