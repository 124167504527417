import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Box } from '@material-ui/core'

import { FilterBox } from '../../../../ui'
import { APPS_FILTERS, APPS_GUILD_FILTERS } from '../../constants'
import { appsFilterStyles } from './apps-filter.styles'
import { ExportToXLSBtn } from '../exporttoxls-btn/exporttoxls-btn.component'

export const AppsFilter = withStyles(appsFilterStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const combos = useSelector((state) => state.combos)
  const role = useSelector((state) => state.global.role)

  const roleFilters = role === 'gremio' ? APPS_GUILD_FILTERS : APPS_FILTERS
  const data = roleFilters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
      if (item.comboId === 'tipo_vivienda') {
        item.values =
          combos[item.comboId] &&
          combos[item.comboId].data.map((el) => ({ key: el.value, value: el.value }))
      }
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.applications.filters.' + item.name + '.title',
      }),
    }
  })
  const buttonsComponent = (
    <Box display="flex" flexGrow={0.5} justifyContent="flex-end">
      <ExportToXLSBtn />
    </Box>
  )
  return <FilterBox filters={data}>{buttonsComponent}</FilterBox>
})
