import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography, Switch, FormControlLabel } from '@material-ui/core'

import { FormFoldingPanel, InfoBox, ThemeButton } from '../../../../ui'
import { useConsultContext } from '../../contexts'
import { APP_PATH_RULES, RESULTS_VIEW_CONFIG, RESULTS_VIEW_FIELDS } from '../../constants'
import { EmpresaBlock } from '../empresa-selector'
import { getGestorGC } from '../../calls/consult-information.calls'
import { useModalContext } from '../../../../ui'
import { AcceptationDialog } from '../../../../ui/dialogs'

export const ConsultResultsView = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const {
    isLoading,
    selectedHome: home,
    consultData,
    empresa,
    selectedHome,
    nextStep,
    reset,
    checkIfAppExist,
    changeIsContratable,
    isContratable,
    setEmpresa,
    changeCustomerForm,
  } = useConsultContext()

  const {
    actions: { open },
  } = useModalContext()

  const combos = useSelector((state) => state.combos)
  const homeConsultData = { ...home, ...consultData }

  const handleNext = () => {
    if (consultData.mercado === 'GC' || consultData === 'CC') {
      const mercadoId = combos.tipo_mercado.data.find(
        (mercado) => mercado.key === selectedHome.mercado
      )?.id
      getGestorGC(mercadoId, selectedHome.cod_postal)
        .then((response) => {
          const { nombre, telefono, email } = response
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage(
                { id: 'pages.consult.granconsumo.text' },
                { nombre: nombre, telefono: telefono, email: email }
              ),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
            },
            type: 'centered',
          })
        })
        .catch(() => {
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage({
                id: 'pages.consult.granconsumo.potencia.consumo.fail.text',
              }),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
            },
            type: 'centered',
          })
        })
    } else {
      if (consultData['exterior_pendiente_certificado']) {
        // Unlock fluxe
        nextStep()
      } else {
        checkIfAppExist(home['cidi'])
      }
    }
  }

  const handlePrevious = () => {
    reset()
  }
  const handleToggleFilter = (toogleState) => {
    changeIsContratable(toogleState)
    changeCustomerForm({ name: 'pasar_contratable', value: toogleState })
  }
  const isActivo = home.estado_cups === 'activo' && home.mercado === 'NE'
  const isContratacionEnCurso =
    home.estado_cups === 'contratación en curso' && home.mercado === 'NE'
  const isSinIRC = home.estado_cups === 'sin red/irc' && home.mercado === 'NE'
  const isContratableZeus = home.mercado === 'NE' && home.estado_cups === 'contratable'
  const isEspontanea = home.mercado === 'ES'
  const can_continue_flow = consultData['flowType'] && consultData['flowType'] !== 'none'
  sessionStorage.setItem('origen', consultData['origen'])
  return (
    <>
      <Typography variant="h4" color="textSecondary" paragraph>
        {intl.formatMessage({ id: 'pages.consult.title.1' })}
      </Typography>
      <Typography variant="subtitle1" display="inline">
        {`${intl.formatMessage({ id: 'pages.consult.resultsSubtitle' })}: `}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1" color="textSecondary" display="inline">
          {`${home['tipo_calle']} ${home['nombre_via']} ${home['numero']}` +
            `${home['anexo'] ? '-' + home['anexo'] : ''} ` +
            `${home['bloque'] ? '-' + home['bloque'] : ''} ` +
            `${home['escalera'] ? '-' + home['escalera'] : ''} ` +
            `${home['piso']} ${home['puerta']}` +
            `, ${home['cod_postal']} ${home['municipio']} ${home['provincia']}`}
        </Typography>
        {consultData.estado_cups !== 'CONTRATABLE' ? (
          <FormControlLabel
            variant="outlined"
            label="Ya esta conectada, pasar a contratable"
            control={
              <Switch
                {...{
                  onClick: ({ target: { checked } }) => handleToggleFilter(checked),
                  checked: isContratable,
                }}
              />
            }
            labelPlacement="start"
          />
        ) : null}
      </Box>
      {!can_continue_flow && selectedHome.motivo_no_captabilidad !== '' ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <InfoBox
            severity="warning"
            message={
              consultData?.['mensaje'] ||
              formatMessage({ id: 'pages.consult.continue.flowtype.captabilidad' })
            }
            style={{ fontSize: 20, fontWeight: 600, alignItems: 'center' }}
          />
        </Box>
      ) : null}
      {isEspontanea ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <InfoBox
            severity="warning"
            message={formatMessage({ id: 'pages.consult.continue.flowtype.alert.espontanea' })}
          />
        </Box>
      ) : null}
      {isContratableZeus ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <InfoBox
            severity="warning"
            message={formatMessage({ id: 'pages.consult.continue.flowtype.alert.contratable' })}
          />
        </Box>
      ) : null}
      {isSinIRC ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <InfoBox
            severity="warning"
            message={formatMessage({ id: 'pages.consult.continue.flowtype.alert.sinirc' })}
          />
        </Box>
      ) : null}
      {isContratacionEnCurso ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <InfoBox
            severity="warning"
            message={formatMessage({
              id: 'pages.consult.continue.flowtype.alert.contratacionencurso',
            })}
          />
        </Box>
      ) : null}
      {isActivo ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <InfoBox
            severity="warning"
            message={formatMessage({ id: 'pages.consult.continue.flowtype.alert.activa' })}
          />
        </Box>
      ) : null}
      <Box bgcolor="grey.300" p={3} mt={3} mb={3}>
        {RESULTS_VIEW_CONFIG.map((block, i) => (
          <div key={i}>
            <FormFoldingPanel
              {...{
                data: homeConsultData,
                combos,
                intl,
                block,
                fieldList: RESULTS_VIEW_FIELDS({ combos, intl }),
              }}
            />
          </div>
        ))}
      </Box>
      <EmpresaBlock empresa={empresa} setEmpresa={setEmpresa} home={home} />
      <Box mt={3} display="flex" justifyContent="space-around">
        <ThemeButton
          type="submit"
          variant="outlined"
          labelM="lg"
          disabled={isLoading}
          onClick={handlePrevious}
        >
          {intl.formatMessage({ id: 'pages.consult.resultsButtons.goBack' })}
        </ThemeButton>
        {empresa && can_continue_flow ? (
          isEspontanea ||
          isContratableZeus ||
          isSinIRC ||
          isActivo ||
          isContratacionEnCurso ? null : (
            <ThemeButton type="submit" labelM="lg" onClick={handleNext} loading={isLoading}>
              {intl.formatMessage({
                id: `pages.consult.resultsButtons.goNext.${
                  APP_PATH_RULES[consultData?.['flowType']].certificate ? '2' : '1'
                }`,
              })}
            </ThemeButton>
          )
        ) : null}
      </Box>
    </>
  )
}
