export const DEFAULT_DATA_SIZE = 5
export const APPLICATION_TABLE_COLUMNS_KEY = `applications`

export const APPS_TABLE_COLUMNS = [
  { field: 'pendiente_peticionario', width: 40, exportable: false },
  {
    field: 'comunicaciones_pendientes',
    width: 40,
    exportable: false,
  },
  {
    field: 'valoraciones_pendientes',
    width: 40,
    exportable: false,
  },
  {
    field: 'solicitud_id',
    align: 'right',
    width: 180,
  },
  {
    field: 'fecha_creacion_solicitud',
    align: 'left',
    type: 'date',
    width: 145,
  },
  {
    field: 'solicitud_estado',
    exportType: 'combo',
    comboId: 'estados_solicitud_instalador',
    align: 'left',
    width: 270,
  },
  {
    field: 'tipo_solicitud',
    align: 'left',
    width: 160,
  },
  {
    field: 'cod_postal',
    align: 'left',
    width: 140,
  },
  {
    field: 'direccion',
    align: 'left',
    width: 420,
  },
  {
    field: 'cod_solicitud_zeus',
    align: 'left',
    width: 180,
  },
  {
    field: 'cups',
    align: 'left',
    width: 240,
  },
  {
    field: 'tipo_vivienda',
    align: 'left',
    width: 160,
  },
]

export const GUILD_TABLE_COLUMNS = [
  { field: 'pendiente_peticionario', width: 40, exportable: false },
  {
    field: 'comunicaciones_pendientes',
    width: 40,
    exportable: false,
  },
  {
    field: 'empresa_ca_id',
    exportType: 'custom_combo',
    comboId: 'empresas',
    align: 'left',
    width: 220,
  },
  {
    field: 'solicitud_id',
    align: 'left',
    width: 180,
  },
  {
    field: 'fecha_creacion_solicitud',
    align: 'left',
    type: 'date',
    width: 145,
  },
  {
    field: 'solicitud_estado',
    exportType: 'combo',
    comboId: 'estados_solicitud_instalador',
    align: 'left',
    width: 270,
  },
  {
    field: 'tipo_solicitud',
    align: 'left',
    width: 160,
  },
  {
    field: 'cod_postal',
    align: 'left',
    width: 70,
  },
  {
    field: 'direccion',
    align: 'left',
    width: 420,
  },
  {
    field: 'cod_solicitud_zeus',
    align: 'left',
    width: 180,
  },
  {
    field: 'cups',
    align: 'left',
    width: 240,
  },
  {
    field: 'tipo_vivienda',
    align: 'left',
    width: 160,
  },
]

export const APPS_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}
