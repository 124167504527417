import React, { useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import {
  CAMPAIGN_TABLE_ACTIONS,
  CAMPAIGN_TABLE_COLUMNS,
} from '../constants/campaign-table.constants'
// import { TableActions } from '../table-actions'
import { campaignTableStyles } from './campaign-table.styles'
import { TableUrlPagination } from '../../../ui'
// import { ADMIN_ROLES } from '../../../../core/constants'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const CampaignTable = ({ campanas, handleChange, formState,pagination, handleChangePagination }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const role = useSelector((state) => state.global.role)
  const {
    data,
    // search,
    pagination: { total_campanas: totalEntries },
    isLoading,
  } = useSelector((state) => state.applications)
  const combos = useSelector((state) => state.combos)

  const computeDate = (date) => {
    const placeholderDate = new Date(date)
    const formattedDate = placeholderDate.toLocaleDateString('es-ES')
    return formattedDate
  }

  const handleSelectRow = (rows, row) => {
    if (formState.camp1 && formState.camp1.id !== row.id) {
      return
    }
    if (!formState.camp1) {
      handleChange({
        target: {
          name: 'camp1',
          value: row,
        },
      })
    }
    if (formState.camp1 && formState.camp1.id === row.id) {
      handleChange({
        target: {
          name: 'camp1',
          value: undefined,
        },
      })
    }
  }

  const formattedColumns = useMemo(
    () =>
      CAMPAIGN_TABLE_COLUMNS.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.users.table.columns.${column.field}.title` }),
          cellStyle: campaignTableStyles.cell,
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useMemo(() => {
    return campanas.map((item) => {
      return {
        ...item,
        fechainicio: computeDate(item.fechainicio),
        fechafin: computeDate(item.fechafin),
        tableData: {
          ...item.tableData,
        },
      }
    })
  }, [campanas])

  const tableOptions = useMemo(
    () => ({
      toolbar: true,
      selection: true,
      showSelectAllCheckbox: false,
      showTitle: false,
      searchFieldStyle: { width: 1100 },
      searchFieldAlignment: 'left',
      showTextRowsSelected: false,
      tableLayout: 'fixed',
      headerStyle: campaignTableStyles.header,
      draggable: false,
      selectionProps: (rowData) => ({
        disabled: formState.camp1 && formState.camp1.id !== rowData.id ? true : false,
      }),
    }),
    [formState.camp1]
  )

  // const tableComponents = useMemo(
  //   () => ({
  //     Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
  //     Cell: (props) => (
  //       <MTableCell {...props} style={{ padding: 0 }}>
  //         {props.columnDef.field === 'active' ? <TableActions {...props} /> : props.children}
  //       </MTableCell>
  //     ),
  //   }),
  //   []
  // )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      options={tableOptions}
      totalEntries={pagination.total_entries}
      // search={search}
      // components={tableComponents}
      //Not transformed in the url table interface:
      data={processedData}
      isLoading={pagination.loading}
      onSelectionChange={handleSelectRow}
      onChangePagination={handleChangePagination}
    />
  )
}
