import { ACTION_TYPES } from '../constants'

export const inspectionsInitialState = {
  type: '',
  company: null,
  loading: true,
  loadingHomeInfo: false,
  error: {},
  apiError: {},
  canContinue: true,
  currentStep: 0,
  inspection: {},
  addresses: [],
  // addresses: [
  //   {
  //     cups: 'ES0222120063905616XC',
  //     nombre: 'VIRGINIA',
  //     apellido_1: 'DEL OLMO',
  //     apellido_2: 'CABALLERO',
  //     provincia: 'CASTELLON',
  //     municipio: 'BURRIANA',
  //     cp: '12530',
  //     calle: 'DE NULES',
  //     portal: null,
  //     escalera: null,
  //     piso: '03',
  //     puerta: '00002',
  //     numero_finca: '0017',
  //     tipo_de_gas: 'GAS NATURAL',
  //     tipo_instalacion: 'IRI (DOMESTICO < 70kW)',
  //     comercializadora: 'NATURGY IBERIA, S.A.',
  //     sociedad: 'NEDGIA CEGAS, S.A.',
  //     anyo_fin_vigencia_ip3: 2012,
  //     criterio_aceptacion_ip3: 'Rechazado',
  //     tipologia_cliente: null,
  //     tipo_via: 'CRA',
  //     motivo_rechazo_ip3: 'No aplica IP',
  //     count_cups: 1,
  //     situacion_suministro: 'Corte total',
  //     anomalias: [
  //       {
  //         key: 91,
  //         tipo: 'SECUNDARIA',
  //         grupo:
  //           'Defectos asociados con el estado general de conservaci\u00f3n y condiciones de la instalaci\u00f3n',
  //         cod_anomalia: '71',
  //         value: 'Aparato tipo C 2 SPC imposible hacer orificio',
  //         tipo_instalacion: 'IRI',
  //         corte_total: false,
  //       },
  //       {
  //         key: 30,
  //         tipo: 'SECUNDARIA',
  //         grupo: 'Defectos asociados a la instalaci\u00f3n de los aparatos',
  //         cod_anomalia: '83',
  //         value:
  //           '[AS-3] Funcionamiento incorrecto de los dispositivos de seguridad por extinci\u00f3n o detecci\u00f3n de llama en los aparatos de gas que deban disponer de ellos ',
  //         tipo_instalacion: 'IRI',
  //         corte_total: false,
  //       },
  //     ],
  //     solvedAnomalies: [
  //       {
  //         key: 91,
  //         tipo: 'SECUNDARIA',
  //         grupo:
  //           'Defectos asociados con el estado general de conservaci\u00f3n y condiciones de la instalaci\u00f3n',
  //         cod_anomalia: '71',
  //         value: 'Aparato tipo C 2 SPC imposible hacer orificio',
  //         tipo_instalacion: 'IRI',
  //         corte_total: false,
  //       },
  //     ],
  //     ticket_combustion: { data: 'data,data', name: 'presupuesto_de_ejemplo.pdf', type: 'pdf' },
  //     co_ambiente: '20',
  //     co_corregido: '600',
  //   },
  // ],
  formState: {
    tipo_instalacion: 'IRI',
    une: 'UNE 60620 - 2005',
    // fecha_jca: '2021-09-10T00:00:00.000Z',
    // justificante: { data: 'data,data', name: 'presupuesto_de_ejemplo.pdf', type: 'pdf' },
    // certificado: { data: 'data,data', name: 'presupuesto_de_ejemplo.pdf', type: 'pdf' },
    // factura: { data: 'data,data', name: 'boletin_de_ejemplo.pdf', type: 'pdf' },
    // fecha_factura: '2021-09-23T00:00:00.000Z',
    // fecha_inspeccion: '2021-09-10T00:00:00.000Z',
    // importe: '0',
    // numero_factura: '1234',
    // cups: 'ES0222120055287507AB',
    // instalador: 79,
  },
}

export const inspectionsReducer = (state = inspectionsInitialState, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.SET_TYPE:
      return {
        ...inspectionsInitialState,
        type: payload,
        // loading: false,
      }
    case ACTION_TYPES.SET_COMPANY:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case ACTION_TYPES.RESET_ADDRESSES:
      return {
        ...state,
        addresses: [],
        formState: { ...state.formState, cups: '' },
      }
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case ACTION_TYPES.SET_LOADING_HOME_INFO:
      return {
        ...state,
        loadingHomeInfo: payload,
      }
    case ACTION_TYPES.VALIDATE_INSTALLATION_STEP:
      return {
        ...state,
        currentStep: 1,
        inspection: payload,
        error: {},
      }
    case ACTION_TYPES.VALIDATE_INSPECTION_STEP:
      return {
        ...state,
        currentStep: 2,
        inspection: payload,
        error: {},
      }
    case ACTION_TYPES.CHANGE_FORM:
      return {
        ...state,
        formState: payload,
      }
    case ACTION_TYPES.CHANGE_ADDRESSES:
      return {
        ...state,
        addresses: payload,
      }
    case ACTION_TYPES.SET_ANOMALIES:
      return {
        ...state,
        anomalies: payload,
      }
    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: payload,
        loadingHomeInfo: false,
      }
    case ACTION_TYPES.SET_API_ERROR:
      return {
        ...state,
        apiError: payload,
      }
    case ACTION_TYPES.FETCHING_ADDRESS_BY_CUPS:
      return {
        ...state,
        addresses: [],
        loadingHomeInfo: true,
      }
    case ACTION_TYPES.SET_ADDRESS_BY_CUPS:
      return {
        ...state,
        addresses: [payload],
        loadingHomeInfo: false,
      }
    case ACTION_TYPES.SET_CAN_CONTINUE:
      return {
        ...state,
        canContinue: payload,
      }
    default:
      throw new Error('Unrecognized action')
  }
}
