import React from 'react'
import { Typography, Box, Grid, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { AddressInputBtn } from '../address-selector'
import { useInspectionsContext } from '../../contexts'

export const InstAddressBlock = () => {
  const { addresses, addAddress, deleteAddress, type } = useInspectionsContext()

  const handleSelectAddress = ({ selected, close }) => {
    addAddress(selected)
    close()
  }

  return (
    <>
      {addresses.length ? (
        <Grid item xs={12}>
          <Typography variant="subtitle1">Direcciones</Typography>
          {addresses.map((item, i) => (
            <Box key={i} display="flex">
              <Box p={1} mt={1} bgcolor="grey.100" borderRadius={6} flex={1}>
                <Typography variant="body2">{item['cod_derivacion']}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {`${item['tipo_via'] || ''} ${item['calle'] || ''} ${
                    item['numero_finca'] || ''
                  }, ${item['municipio'] || ''} (${item['cp'] || ''}), ${item['provincia'] || ''}` +
                    '\n' +
                    `${item['portal'] ? ', Portal: ' + item['portal'] : ''}` +
                    '\n' +
                    `${item['escalera'] ? ', Escalera: ' + item['escalera'] : ''}`}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Nº CUPS: {item['count_cups'] || 'No hay información'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item['tipo_de_gas']}
                </Typography>
                {type === 'jca' ? (
                  !item.anomalias.length ? (
                    <Typography variant="body2" color="textSecondary">
                      Anomalías: No hay información
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body2" color="textSecondary">
                        Anomalías:
                      </Typography>
                      <Box pl={2}>
                        {item.anomalias.map((a) => (
                          <Typography variant="body2" color="textSecondary">
                            - {a.value}
                          </Typography>
                        ))}
                      </Box>
                    </>
                  )
                ) : null}
              </Box>
              <Box ml={1} mt={1}>
                <IconButton key="delete" size="small" onClick={() => deleteAddress(item)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <AddressInputBtn onSelect={handleSelectAddress} />
      </Grid>
    </>
  )
}
