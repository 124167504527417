import {
  getUserConsistency,
  checkClientIdentification,
  postApp,
  putApp,
  postCertificate,
  postProvisionalApp,
  postUsuario,
  updateAppDocs,
} from '../calls'
import {
  DOCUMENT_ATTACHMENT_CONFIG,
  postDoc,
  DOCUMENT_ATTACHMENT_GAS_P_CONFIG,
} from '../../documents'
import {
  assembleCustomerFromState,
  assembleDocumentPayload,
  assembleFullCertificate,
  assemblePayloadFromTmpApp,
  assembleAppFromStateUnlockPath,
  assembleTmpAppFromState,
} from './object-assembling.logics'

class InvalidUserError extends Error {
  constructor(message) {
    super(message)
    this.code = 'invalid_user'
  }
}

export const checkUser = (state) => {
  return new Promise(async (resolve, reject) => {
    const userValidity = await getUserConsistency(
      state.customerForm.email,
      state.customerForm.identificador
    )
    if (!userValidity?.validez_usuario) {
      var message
      switch (userValidity.code) {
        case 3512:
          message = 'Este mail es inválido o no existe.'
          break
        case 3635:
          message = 'Este NIF ya está enlazado con otro correo electrónico'
          break
        case 3636:
          message = 'Este correo electrónico ya está enlazado con otro NIF'
          break
        case 3637:
          message =
            'Ya existe una solicitud con diferente correo electrónico/NIF para este mismo punto de suministro'
          break
        default:
          message = userValidity.error || 'El usuario no es válido'
          break
      }
      return reject(new InvalidUserError(message))
    }
    resolve(userValidity)
  })
}

export const checkClientForUnlockPath = (state) => {
  // To handle the unlock path. In this point it is verified if the installer fill
  // in a correct way the client information so he can continue
  return new Promise(async (resolve, reject) => {
    let userValidity = {}
    try {
      userValidity = await checkClientIdentification(
        state.consultData.exterior_pendiente_certificado, //id_solicitud
        state.customerForm.email,
        state.customerForm.identificador
      )
    } catch (e) {
      //feedback('error', e.error)
      return reject(new InvalidUserError(e.error))
    }
    resolve(userValidity)
  })
}

const stepTemporaryApp = (state) => {
  return new Promise(async (resolve, reject) => {
    const tmpApplication = await postProvisionalApp(assembleTmpAppFromState(state))
    if (!tmpApplication || !tmpApplication.id) return reject('no_tmp_app')
    resolve(tmpApplication)
  })
}

const stepUser = (state) => {
  return new Promise((resolve, reject) => {
    postUsuario(assembleCustomerFromState(state))
      .then((userData) => {
        resolve(userData['id'])
      })
      .catch(() => {
        return reject('user_failed')
      })
  })
}

const stepApp = (appSubtype, state, tmpApplication, id_client, installerId) => {
  return new Promise((resolve, reject) => {
    postApp(assemblePayloadFromTmpApp(appSubtype, state, tmpApplication, id_client, installerId))
      .then((appData) => {
        resolve({
          id_app: appData['id'],
          id_budget: appData['presupuesto_exterior']?.id,
          appData,
        })
      })
      .catch(() => {
        return reject('app_failed')
      })
  })
}

const stepUpdateApp = (id_solicitud, state, installerId) => {
  return new Promise((resolve, reject) => {
    putApp(id_solicitud, assembleAppFromStateUnlockPath(state, installerId))
      .then((appData) => {
        resolve({
          id_app: appData['id'],
          id_budget: appData['presupuesto_exterior']?.id,
          id_client: appData['usuario']?.id,
          appData,
        })
      })
      .catch(() => {
        return reject('app_failed')
      })
  })
}

const stepDocuments = (documentsToUpload, form, id_client, id_app, id_budget) => {
  return new Promise((resolve, reject) => {
    Promise.all(
      documentsToUpload.map(async (doc) => {
        const file = DOCUMENT_ATTACHMENT_CONFIG[doc] || DOCUMENT_ATTACHMENT_GAS_P_CONFIG[doc]
        if (form[doc]) {
          const documentResponse = await postDoc(
            assembleDocumentPayload(file, form, id_client, id_app, id_budget)
          )
          return { id: file.id, field: file.field, value: documentResponse.id }
        }
      })
    )
      .then((uploadedDocuments) => {
        resolve(uploadedDocuments)
      })
      .catch((err) => {
        return reject('doc_failed')
      })
  })
}

const stepLinkDocuments = (uploadedDocuments, id_budget, id_app, appData) => {
  return new Promise(async (resolve, reject) => {
    let docsUpdatePayload = {}
    uploadedDocuments.forEach((d) => {
      if (d) docsUpdatePayload[d.id] = d.value
    })
    const { croquis_id, ...restDocsUpdatePayload } = docsUpdatePayload
    const budgetPayload = {}
    if (croquis_id) {
      budgetPayload.croquis_id = croquis_id
    }

    try {
      await updateAppDocs(id_app, restDocsUpdatePayload).catch(() => {
        throw new Error('app_update_failed')
      })
    } catch (err) {
      return reject(err)
    }
    resolve()
  })
}

const stepCertificate = (endpoint, certificateForm, id_client, id_app) => {
  const formattedCertificateForm = {
    ...certificateForm,
    camp1: certificateForm.camp1 ? certificateForm.camp1.codigo : null,
    camp2: certificateForm.camp2 ? certificateForm.camp2.codigo : null,
    camp3: certificateForm.camp3 ? certificateForm.camp3.codigo : null,
  }
  return new Promise((resolve, reject) => {
    postCertificate(endpoint, assembleFullCertificate(formattedCertificateForm, id_client, id_app))
      .then((data) => resolve(data))
      .catch((err) => {
        return reject(err)
      })
  })
}

export const createApp = (state, installerId, appSubtype, documentsToUpload) => {
  return new Promise(async (resolve, reject) => {
    let tmpApplication = {}
    try {
      //Checkuser
      await checkUser(state).catch((err) => {
        throw err
      })

      //Temporary app
      tmpApplication = await stepTemporaryApp(state).catch((err) => {
        throw new Error(err)
      })

      //Customer
      const id_client = await stepUser(state).catch((err) => {
        throw new Error(err)
      })

      //App
      const { id_app, id_budget, appData } = await stepApp(
        appSubtype,
        state,
        tmpApplication,
        id_client,
        installerId
      ).catch((err) => {
        throw new Error(err)
      })

      //Documents
      const uploadedDocuments = await stepDocuments(
        documentsToUpload,
        state.customerForm,
        id_client,
        id_app,
        id_budget
      ).catch((err) => {
        throw new Error(err)
      })

      //Link docs to app & budget
      await stepLinkDocuments(uploadedDocuments, id_budget, id_app, appData).catch((err) => {
        throw err
      })

      //To throw manual error:
      //throw new Error('Manual failure')

      resolve(id_app)
    } catch (err) {
      console.debug('error during app creation: ', err)
      if (tmpApplication.id) {
        return reject({ tmpApp: tmpApplication.id })
      } else {
        return reject(err)
      }
    }
  })
}

export const updateAppAndCreateCertificate = (
  state,
  installerId,
  appSubtype,
  documentsToUpload,
  documentsToUploadCertificate,
  endpoint
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const id_budget = undefined // TODO eliminar - no es importante
      const id_client = state.certificateForm.client_id
      const id_app = state.consultData.exterior_pendiente_certificado

      // Try to upload documents
      const certificateDocuments = await stepDocuments(
        documentsToUploadCertificate,
        state.certificateForm,
        id_client,
        id_app,
        id_budget
      )

      // Update application setting the installer
      await stepUpdateApp(
        state.consultData.exterior_pendiente_certificado, // id_solicitud
        state,
        installerId
      ).catch((err) => {
        throw new Error(err)
      })

      const updateDocsFromCertificateForm = {}
      certificateDocuments.forEach((doc) => {
        if (!doc || !doc.field) {
          return
        }
        updateDocsFromCertificateForm[doc.id] = doc.value
        updateDocsFromCertificateForm[doc.field] = undefined
      })

      // Certificate creation
      await stepCertificate(
        endpoint,
        { ...state.certificateForm, ...updateDocsFromCertificateForm },
        id_client,
        id_app
      ).catch((err) => {
        throw new Error(err)
      })

      resolve(id_app)
    } catch (err) {
      console.debug(err)
      return reject(err)
    }
  })
}

export const createAppAndCertificate = (
  state,
  installerId,
  appSubtype,
  documentsToUpload,
  documentsToUploadCertificate,
  endpoint
) => {
  return new Promise(async (resolve, reject) => {
    let tmpApplication = {}
    try {
      tmpApplication = await stepTemporaryApp(state).catch((err) => {
        throw new Error(err)
      })

      const id_client = await stepUser(state).catch((err) => {
        throw new Error(err)
      })

      const { id_app, id_budget } = await stepApp(
        appSubtype,
        state,
        tmpApplication,
        id_client,
        installerId
      ).catch((err) => {
        throw new Error(err)
      })

      const uploadedDocuments = await stepDocuments(
        documentsToUpload,
        state.customerForm,
        id_client,
        id_app,
        id_budget
      ).catch((err) => {
        throw new Error(err)
      })
      await stepLinkDocuments(uploadedDocuments, id_budget, id_app).catch((err) => {
        throw err
      })

      const updateDocsFromCertificateForm = {}
      const certificateDocuments = await stepDocuments(
        documentsToUploadCertificate,
        state.certificateForm,
        id_client,
        id_app,
        id_budget
      )
      certificateDocuments.forEach((doc) => {
        if (!doc || !doc.field) {
          return
        }
        updateDocsFromCertificateForm[doc.id] = doc.value
        updateDocsFromCertificateForm[doc.field] = undefined
      })

      await stepCertificate(
        endpoint,
        { ...state.certificateForm, ...updateDocsFromCertificateForm },
        id_client,
        id_app
      ).catch((err) => {
        throw new Error(err)
      })

      resolve(id_app)
    } catch (err) {
      console.debug(err)
      if (tmpApplication.id) {
        return reject({ tmpApp: tmpApplication.id })
      } else {
        return reject(err)
      }
    }
  })
}
