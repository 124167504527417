export * from './filter.constants'
export * from './table.constants'

export const APPS_FILTERS = [
  {
    name: 'solicitud_estado',
    comboId: 'estados_solicitud_instalador_filtro',
    size: 'small',
    multiple: true,
  },
  {
    name: 'tipo_vivienda',
    comboId: 'tipo_vivienda',
    size: 'small',
    multiple: true,
  },
  {
    name: 'tipo_solicitud',
    comboId: 'tipo_solicitud',
    size: 'small',
    multiple: true,
  },
]

export const APPS_GUILD_FILTERS = [
  // {
  //   name: 'cp_municipio',
  //   filterType: 'autocomplete',
  //   optionKey: 'cod_postal',
  //   renderOption: (option) => `${option['cod_postal']} - ${option['municipio']}`,
  //   request: getCompanies,
  //   size: 'small',
  // },
  {
    name: 'eeii_id',
    filterType: 'autocomplete',
    comboId: 'empresas',
    size: 'small',
  },
  {
    name: 'solicitud_estado',
    comboId: 'estados_solicitud_instalador_filtro',
    size: 'small',
    multiple: true,
  },
  // {
  //   name: 'fecha_desde',
  //   filterType: 'date',
  //   size: 'small',
  // },
  // {
  //   name: 'fecha_hasta',
  //   filterType: 'date',
  //   size: 'small',
  // },
]

export const APPS_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}
