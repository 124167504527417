import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress } from '@material-ui/core'

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { BasicTable } from '../../../../ui'
import { APP_EVENTS_TABLE_CONFIG } from '../../constants'

export const AppEventsTable = ({ model }) => {
  const { id } = model
  const intl = useIntl()
  const { getById } = useAjaxContext()

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const getAppEvents = (id) => {
    getById('/bitacora', id)
      .then(({ data }) => {
        const sortedData = data.sort(
          (a, b) => new Date(b['dg_ts_insert']) - new Date(a['dg_ts_insert'])
        )
        setEvents(sortedData)
        setLoading(false)
      })
      .catch(() => {
        console.error('Error recuperando el historial de solicitud')
        setLoading(false)
      })
  }

  useEffect(() => {
    getAppEvents(id)
  }, [model]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading)
    return (
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  return events.length ? (
    <BasicTable config={APP_EVENTS_TABLE_CONFIG(intl)} data={events} loading={loading} />
  ) : (
    intl.formatMessage({ id: 'pages.application.detail.history.emptyList' })
  )
}
