import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import {
  CERTIFICATES_DEVICE_CONFIG,
  CERTIFICATES_FORM_CONFIG,
  SCR_UPLOAD_BUTTONS_CONFIG,
} from '../../constants'
import { CertificateFormStructure, useCertificatesDialogs } from '../../../certificates'
import { useErrorReducer } from '../../../../core/utils/useErrorReducer'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents'
import { useConsultContext } from '../../contexts'
import { getGestorGC } from '../../calls/consult-information.calls'
import { useModalContext } from '../../../../ui'
import { AcceptationDialog } from '../../../../ui/dialogs'
import { useCombos } from '../../../combos'

export const CertificateForm = ({ form, application }) => {
  const intl = useIntl()

  const combos = useCombos(['tipo_mercado'], ['tipo_mercaddo'])
  //Data
  const {
    certificateForm: formState,
    changeCertificateForm,
    createCertificate,
    consultData,
    selectedHome,
  } = useConsultContext()
  const prepareValues = (name, value) => {
    //Special case for services field in certificate form
    if (name === 'services') {
      if (value && value.includes('calefaccion')) {
        if (!value.includes('agua_caliente')) {
          value = [...value, 'agua_caliente']
        }
      }
    }
    return value
  }

  const handleChange = useCallback(
    (event) => {
      let { name, value } = event.target
      value = prepareValues(name, value)
      changeCertificateForm({ name, value })
    },
    [changeCertificateForm]
  )
  const userId = useSelector((state) => state.global.user_id)

  //Preparing validation and submission
  const { errors, resetErrors, updateError, validateField } = useErrorReducer()
  const [showErrors, setShowErrors] = useState(false)
  const [check, setCheck] = useState(false)
  const { warnCertificateAcceptance } = useCertificatesDialogs()
  const {
    actions: { open },
  } = useModalContext()
  const customValidation = () => {
    return new Promise((resolve, reject) => {
      setShowErrors(true)
      if (!check) {
        return reject('Incorrect data')
      }
      for (let error in errors) {
        if (errors[error]) {
          return reject('Incorrect data')
        }
      }
      resolve()
    })
  }
  const handleSubmitForm = useCallback(
    () =>
      new Promise((resolve, reject) => {
        createCertificate(
          userId,
          'scr',
          SCR_UPLOAD_BUTTONS_CONFIG.map((el) => el.doc),
          form.documents,
          form.endpoint
        )
          .then(() => {
            resolve()
          })
          .catch((err) => {
            return reject(err)
          })
      }),
    [userId, form] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getAdditionalInfo = () => {
    const ADDITIONAL_INFO = []
    if (
      formState['services'] &&
      formState['services'].indexOf('calefaccion') !== -1 &&
      !formState['d_CC_id']
    ) {
      /*ADDITIONAL_INFO.push({ name: 'rite' }) //TODO Should we remove?*/
      console.debug('We remove RITE document')
    }
    return ADDITIONAL_INFO
  }
  const handleSubmitFormRequest = () => {
    if (formState.tipo_mercado === 'GC' || formState.tipo_mercado === 'CC') {
      const mercadoId = combos.tipo_mercado.data.find(
        (mercado) => mercado.key === consultData.mercado
      )?.id
      const mercadoValue =
        formState.tipo_mercado === 'GC'
          ? 'gran_consumo'
          : formState.tipo_mercado === 'CC'
          ? 'caldera_centralizada'
          : null

      getGestorGC(mercadoId, selectedHome.cod_postal, mercadoValue)
        .then((response) => {
          const { nombre, telefono, email } = response
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage(
                { id: 'pages.consult.granconsumo.text' },
                { nombre: nombre, telefono: telefono, email: email }
              ),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
            },
            type: 'centered',
          })
        })
        .catch(() => {
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage({ id: 'pages.consult.granconsumo.fail.text' }),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
            },
            type: 'centered',
          })
        })
    } else {
      customValidation()
        .then(() => {
          warnCertificateAcceptance(handleSubmitForm, getAdditionalInfo())
        })
        .catch((error) => console.debug('Error: ', error))
    }
  }

  //Validate upon data change
  useEffect(() => {
    resetErrors()
    //Validate errors for the present form
    form.validation.forEach((line) => {
      validateField(line.custom_validation, line.field, formState[line.field], line.aux)
    })
    //Validate presence of devices
    if (form.devices && form.devices.length) {
      let error = true
      let errorDevice = false
      form.devices.forEach((dev) => {
        const device = devices[dev]
        if (formState[device.id + '_nuevos'] || formState[device.id + '_transformar']) {
          error = false
          if (
            !formState[device.id + '_marca'] ||
            !formState[device.id + '_potencia'] ||
            !(formState[device.id + '_nombre'] || device.label)
          ) {
            errorDevice = true
          }
        }
      })
      updateError('devices', error)
      updateError('singleDevice', errorDevice)
    } else {
      updateError('devices', false)
      updateError('singleDevice', false)
    }
  }, [form.validation, form.devices, formState]) // eslint-disable-line react-hooks/exhaustive-deps

  //Config
  const { deviceList, devices } = useMemo(() => {
    const devices = CERTIFICATES_DEVICE_CONFIG({ intl })
    const deviceList = form.devices ? form.devices.map((dev) => devices[dev]) : null
    return { devices, deviceList }
  }, [form, intl])
  const documentList = useMemo(() => {
    const documents = DOCUMENT_ATTACHMENT_CONFIG
    return form.documents?.length ? form.documents.map((doc) => documents[doc]) : null
  }, [form])
  const services = useMemo(() => form.services, [form])
  const fieldList = useMemo(() => {
    return CERTIFICATES_FORM_CONFIG({
      combos,
      intl,
      onChange: (event) => {
        handleChange(event)
      },
    })
  }, [combos, intl, handleChange])

  return (
    <CertificateFormStructure
      {...{
        form,
        formState,
        handleChange,
        fieldList,
        documentList,
        deviceList,
        services,
        errors,
        showErrors,
        check,
        setCheck,
        onSubmit: handleSubmitFormRequest,
        viewCampanas: true,
      }}
    />
  )
}

export default CertificateForm
