import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography, Grid, Container } from '@material-ui/core'

import { TextInput, SelectInput, ThemeButton } from '../../../../ui'
import { NEW_ADDRESS_FIELDS } from '../../constants'
import { useCombos } from '../../../combos'

// const MOCK_FORM_STATE = {
//   tipo_instalacion: 'IND',
//   une: 'UNE 60670 - 2014',
//   cups: 'ES02221200552875lala',
//   nombre: 'VIRGINIA',
//   apellido_1: 'DEL OLMO',
//   apellido_2: 'CABALLERO',
//   provincia: 'CASTELLON',
//   municipio: 'BURRIANA',
//   cp: '12530',
//   calle: 'DE NULES',
//   portal: null,
//   escalera: null,
//   piso: '03',
//   puerta: '00002',
//   numero_finca: '0017',
//   tipo_de_gas: 'GAS NATURAL',
//   tipo_via: 'CRA',
// }

export const CreateAddressDialog = ({ close, initialFormState, createAddress }) => {
  const intl = useIntl()
  const { formatMessage: fm } = intl
  const combos = useCombos([])

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState(initialFormState)
  // const [formState, setFormState] = useState({ ...MOCK_FORM_STATE, ...initialFormState })

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    createAddress(formState)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const addAddressFields = NEW_ADDRESS_FIELDS({
    formState,
    onChange: handleChangeForm,
    combos,
    intl,
  })

  return (
    <Container maxWidth="lg">
      <Box p={2} pl={[2, 6, 10]} pr={[2, 6, 10]}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h5" color="primary">
              No hemos encontrado el CUPS
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Por favor, introduce los datos de la dirección asociada al CUPS.
          </Typography>
          <Box p={3} bgcolor="grey.200" borderRadius={6}>
            <form onSubmit={handleSubmit}>
              <Box mb={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <SelectInput {...{ ...addAddressFields.TIPO_INSTALACION }} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SelectInput {...{ ...addAddressFields.UNE }} />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextInput {...{ ...addAddressFields.CUPS }} />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {/* <SelectInput {...{ ...addAddressFields.TIPO_GAS }} /> */}
                    <TextInput {...{ ...addAddressFields.TIPO_GAS }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput {...{ ...addAddressFields.NOMBRE_TITULAR }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput {...{ ...addAddressFields.APELLIDO_1_TITULAR }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput {...{ ...addAddressFields.APELLIDO_2_TITULAR }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput {...{ ...addAddressFields.PROVINCIA }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput {...{ ...addAddressFields.MUNICIPIO }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput {...{ ...addAddressFields.CODIGO_POSTAL }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput {...{ ...addAddressFields.TIPO_VIA }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput {...{ ...addAddressFields.CALLE }} />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextInput {...{ ...addAddressFields.NUMERO_FINCA }} />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextInput {...{ ...addAddressFields.PORTAL }} />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextInput {...{ ...addAddressFields.ESCALERA }} />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextInput {...{ ...addAddressFields.PISO }} />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextInput {...{ ...addAddressFields.PUERTA }} />
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="center">
                <Box mb={[3, 0]} mr={6}>
                  <ThemeButton onClick={close} color="inherit" disabled={loading}>
                    {fm({ id: 'global.cancel' })}
                  </ThemeButton>
                </Box>
                <Box>
                  <ThemeButton type="submit" color="primary" loading={loading} disabled={loading}>
                    {fm({ id: 'global.continue' })}
                  </ThemeButton>
                </Box>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Box>
    </Container>
  )
}
