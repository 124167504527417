import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Grid, CircularProgress } from '@material-ui/core'

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { ThemeButton, FileUploader, UploadTooltip } from '../../../../ui'
import { documentsUploadStyles } from './documents-upload.style'
import { UPLOAD_BUTTONS_CONFIG, CANT_CREATE_CERTIFICATE_STATES } from '../../constants'
import { feedback } from '../../../../core/feedback'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents'

export const DocumentsUploadComponent = withStyles(documentsUploadStyles)(
  ({ classes, close, application, setApplication }) => {
    const intl = useIntl()
    const { putById } = useAjaxContext()

    const [model, setModel] = useState({ ...application })
    const [loading, setLoading] = useState(false)

    const handleUploadBudgetFile = (docData, docId) => {
      if (docData['tipo'] === 'FI' || docData['tipo'] === 'CI') {
        if (!model['presupuesto']) {
          feedback('error', 'Presupuesto exterior no encontrado')
          return
        }
        const payload = {
          [docId]: docData['id'],
        }

        setLoading(true)
        putById('/presupuesto', model['presupuesto']['id'], payload)
          .then(({ data }) => {
            setModel({ ...model, presupuesto: data.presupuesto_exterior })
            setApplication({ ...data, pendiente_peticionario: application.pendiente_peticionario })
            setLoading(false)
            feedback(
              'success',
              intl.formatMessage({ id: 'pages.application.view.documentUploaderr.feedback.1' })
            )
          })
          .catch(() => {
            feedback(
              'error',
              intl.formatMessage({ id: 'pages.application.view.documentUploadErr.feedback.1' })
            )
            setLoading(false)
          })
      } else {
        setLoading(true)
        const payload = { [docId]: docData['id'] }
        putById('/solicitudes/update_docs', model['id'], payload)
          .then(({ data }) => {
            setModel({ ...data, presupuesto: data.presupuesto_exterior })
            setApplication({ ...data, pendiente_peticionario: application.pendiente_peticionario })
            setLoading(false)
            feedback(
              'success',
              intl.formatMessage({ id: 'pages.application.view.documentUploaderr.feedback.1' })
            )
          })
          .catch(() => {
            console.error('Error changing application doc')
            setLoading(false)
          })
      }
    }

    const uploadButtonArray = UPLOAD_BUTTONS_CONFIG.map((doc) => DOCUMENT_ATTACHMENT_CONFIG[doc])
      .filter((doc) => {
        if (
          (doc.id === 'd_IRG1_id' || doc.id === 'd_IRG2_id' || doc.id === 'd_IRG3_id') &&
          (!model[doc.subfield] ||
            !model[doc.subfield].estado ||
            model[doc.subfield].estado === 'Tramitado' ||
            model[doc.subfield].estado === 'Anulado' ||
            CANT_CREATE_CERTIFICATE_STATES[application.estado])
        )
          return false
        return !doc.subfield || model[doc.subfield]
      })
      .map((doc) => {
        return {
          ...doc,
          label: intl.formatMessage({
            id: `pages.application.view.documentViewer.documents.${doc.field}`,
          }),
          fileData: doc.subfield ? model[doc.subfield][doc.model] : model[doc.model],
          estado: doc.subfield ? model[doc.subfield].estado : null,
        }
      })

    const handleUploadError = (data) => {
      feedback('error', data?.mssg || 'La subida del documento no se pudo completar.')
    }

    return (
      <Box p={4}>
        <Box mb={4}>
          <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '1em' }}>
            Recomendamos adjuntar los documentos correspondientes a sus apartados para evitar el
            rechazo de la petición.
          </Typography>
          <Grid container spacing={2}>
            {uploadButtonArray.map((uploadButton) => {
              return (
                <Grid key={uploadButton.id} item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Box>
                        <Typography display="inline" variant="body2">
                          {uploadButton.label}
                        </Typography>
                        <UploadTooltip file={uploadButton} />
                      </Box>
                      <Typography
                        variant="body2"
                        color={uploadButton.estado === 'Rechazado' ? 'error' : 'initial'}
                      >
                        {uploadButton.fileData?.nombre}
                      </Typography>
                    </Box>
                    <FileUploader
                      applicationId={model['id']}
                      budgetId={model['presupuesto']?.id}
                      clientId={model['usuario']['id']}
                      onSuccess={(data) => handleUploadBudgetFile(data, uploadButton.id)}
                      onError={handleUploadError}
                      {...uploadButton}
                    >
                      {(props) => (
                        <span
                          className="MuibuttonBase-root MuiButton-root MuiButton-outlined"
                          style={{ marginLeft: 10 }}
                          disabled={loading}
                        >
                          {props.loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            intl.formatMessage({ id: 'pages.certificates.documents.button.add' })
                          )}
                        </span>
                      )}
                    </FileUploader>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center">
          <ThemeButton onClick={() => close()} color="primary" labelM="lg" loading={loading}>
            {intl.formatMessage({ id: 'global.close' })}
          </ThemeButton>
        </Box>
      </Box>
    )
  }
)
