import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Typography, Grid, Divider } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { TextInput, SelectInput, DateInput, FileUploaderInput } from '../../../../ui'
import { useCombos } from '../../../combos'
import { useInspectionsContext } from '../../contexts'
import { AnomaliesInput } from './anomalies-input.component'

const RESULTS_WITH_ANOMALIES = [
  'Con anomalías secundarias',
  'Con anomalías principales',
  'Con anomalías secundarias y principales',
  'No deja cortar',
]

const RESULTS_WITH_MAIN_ANOMALIES = [
  'Con anomalías principales',
  'Con anomalías secundarias y principales',
  'No deja cortar',
]

export const IPInspInfoBlock = () => {
  const { formatMessage } = useIntl()
  const global = useSelector((state) => state.global)
  const isGuild = !!global.gremio
  const { formFields, formState, addresses, changeAddress } = useInspectionsContext()
  const getAnomaliesComboKeys = React.useCallback(() => {
    switch (formState.tipo_instalacion) {
      case 'IRI': {
        if (addresses[0].tipo_instalacion === 'IRI (DOMESTICO >= 70KW)')
          return [
            'anomalias_principales_iri_gran_potencia',
            'anomalias_secundarias_iri_gran_potencia',
          ]
        else return ['anomalias_principales_iri', 'anomalias_secundarias_iri']
      }
      case 'IRC':
        return ['anomalias_principales_irc', 'anomalias_secundarias_irc']
      case 'IND':
        return ['anomalias_principales_ind', 'anomalias_secundarias_ind']
      default:
        return []
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const combos = useCombos(getAnomaliesComboKeys(formState.tipo_instalacion))

  return (
    <>
      <Grid item xs={12}>
        <SelectInput {...{ ...formFields.INSTALADOR }} />
        {!combos.inspectores?.data.length ? (
          <Alert severity="warning">
            {formatMessage({
              id: `pages.inspections.fields.instalador.warning.${isGuild ? '2' : '1'}`,
            })}
          </Alert>
        ) : null}
      </Grid>
      {addresses.map((address, i) => (
        <React.Fragment key={i}>
          {addresses.length > 1 ? (
            i === 0 ? (
              <Divider style={{ width: '100%', marginTop: 16, marginBottom: 16, height: 2 }} />
            ) : (
              <Divider light style={{ width: '100%', marginTop: 16, marginBottom: 16 }} />
            )
          ) : null}
          {addresses.length > 1 ? (
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                {address.cod_derivacion}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <SelectInput
              {...{
                ...formFields.RESULTADO_INSPECCION,
                value: address.resultado_inspeccion,
                onChange: (e) => changeAddress(e, i),
              }}
            />
          </Grid>
          {RESULTS_WITH_ANOMALIES.some((r) => r === address.resultado_inspeccion) ? (
            <>
              <Grid item xs={12}>
                <AnomaliesInput {...{ address, addressIndex: i }} />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  {...{
                    ...formFields.OBSERVACIONES_ANOMALIAS,
                    value: address.observacion_anomalias,
                    onChange: (e) => changeAddress(e, i),
                  }}
                />
              </Grid>
            </>
          ) : null}
          {RESULTS_WITH_MAIN_ANOMALIES.some((r) => r === address.resultado_inspeccion) ? (
            <Grid item xs={12}>
              <SelectInput
                {...{
                  ...formFields.SITUACION_SERVICIO,
                  value: address.situacion_servicio || '',
                  onChange: (e) => changeAddress(e, i),
                }}
              />
            </Grid>
          ) : null}
          {addresses.length > 1 && i === addresses.length - 1 ? (
            <Divider style={{ width: '100%', marginTop: 16, marginBottom: 16, height: 2 }} />
          ) : null}
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <DateInput {...{ ...formFields.FECHA_INSPECCION }} />
      </Grid>
      <Grid item xs={12}>
        <FileUploaderInput {...{ ...formFields.CERTIFICADO }} />
      </Grid>
    </>
  )
}
