import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'

import { AutocompleteInput, ThemeButton } from '../../../../ui'
import { ADDRESS_FIELDS_CONFIG, ADDRESS_FORM_FIELDS } from './address-form.constants'
import { useAddressCalls } from '../../calls'

export const AddressForm = ({ close, onSelect }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const { getZipMunicipalities, getStreets, getNumbers, getDerivations } = useAddressCalls()

  const [formState, setFormState] = useState({})
  const [townValues, setTownValues] = useState([])
  const [addressValues, setAddressValues] = useState([])
  const [numberValues, setNumberValues] = useState([])
  const [derivationValues, setDerivationValues] = useState([])

  const handleChangeForm = ({ value, name }) => {
    if (value) {
      if (name === 'direccion') {
        getNumbers(value).then((data) => {
          setNumberValues(data)
        })
      }
      if (name === 'numero') {
        getDerivations(value).then((data) => {
          setDerivationValues(data)
        })
      }
    } else {
      setFormState({ ...formState, [name]: null })
    }
    const newAddressForm = { ...formState }
    const startIndex = ADDRESS_FORM_FIELDS.indexOf(name)
    ADDRESS_FORM_FIELDS.forEach((f, i) => {
      if (i === startIndex) newAddressForm[f] = value
      else if (i > startIndex) newAddressForm[f] = null
    })
    setTownValues(newAddressForm['cp_municipio'] === null ? [] : [...townValues])
    setAddressValues(newAddressForm['direccion'] === null ? [] : [...addressValues])
    setNumberValues(newAddressForm['direccion'] === null ? [] : [...numberValues])
    setDerivationValues(newAddressForm['numero'] === null ? [] : [...derivationValues])
    setFormState(newAddressForm)
  }

  const getTownValues = (inputValue) =>
    new Promise((resolve, reject) => {
      getZipMunicipalities(inputValue)
        .then((data) => {
          setTownValues(data)
          return resolve()
        })
        .catch(() => reject())
    })

  const getAddressValues = (inputValue) =>
    new Promise((resolve, reject) => {
      getStreets(formState.cp_municipio.cp, inputValue)
        .then((data) => {
          setAddressValues(data)
          return resolve()
        })
        .catch(() => reject())
    })

  const handleSubmit = () => {
    onSelect({ selected: formState.derivation, close })
  }

  const inputProps = { formState, onChange: handleChangeForm, intl }
  const fieldsProps = ADDRESS_FIELDS_CONFIG(inputProps)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <AutocompleteInput
          {...{ ...fieldsProps.CP_MUNICIPIO, values: townValues, onType: getTownValues }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutocompleteInput
          {...{ ...fieldsProps.DIRECCION, values: addressValues, onType: getAddressValues }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AutocompleteInput {...{ ...fieldsProps.NUMERO, values: numberValues }} />
      </Grid>
      <Grid item xs={12} sm={9}>
        <AutocompleteInput {...{ ...fieldsProps.DERIVATION, values: derivationValues }} />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-around" mt={2}>
          <ThemeButton color="default" onClick={close}>
            {formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
          <ThemeButton color="primary" disabled={!formState['derivation']} onClick={handleSubmit}>
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </Grid>
    </Grid>
  )
}
