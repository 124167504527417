import React from 'react'
import { Box, Typography, withStyles, Checkbox } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useIntl } from 'react-intl'

import { OUTRANGED_APPS, PENDING_APPS, ACCEPTED_APPS } from '../constants/status-po.constants'
import { publicOfferStyles } from './add-public-offers.styles'
import { ORIGEN_KEYS } from '../constants'

export const PublicOfferViewer = withStyles(publicOfferStyles)(
  ({
    classes,
    publicOffer,
    handleShowFile,
    handleDownloadFile,
    handleCheckPublicOffer,
    docsReaded,
    publicOffersToSend,
    generalConditions,
  }) => {
    const { formatMessage } = useIntl()

    return (
      <Box width={'100%'}>
        {ORIGEN_KEYS.map((origen) => (
          <>
            <Box mt={1}>
              <Typography className={classes.origen}>
                <Box sx={{ fontWeight: 'bold' }}>
                  {formatMessage({
                    id: `pages.profile.company.guild.info.publicOffer.market.origen.label.${origen}`,
                  })}
                </Box>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              {publicOffer?.campañas[origen].length > 0 ? (
                publicOffer.campañas[origen].map((po, i) =>
                  !OUTRANGED_APPS[po.estado] ? (
                    <Box key={i} display="flex" justifyContent="space-between" alignItems="center">
                      <Box alignItems="center" display="flex" ml={1}>
                        <Typography>
                          {po.financiacion ? `${po.nombre} - FINANCIACION` : po.nombre}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mr={1}>
                        {po.financiacion && po.d_condiciones_economicas?.id ? (
                          <PictureAsPdfIcon
                            className={
                              docsReaded.includes(po.id) ||
                              PENDING_APPS[po.estado] ||
                              ACCEPTED_APPS[po.estado]
                                ? classes.docCheked
                                : classes.noChecked
                            }
                            onClick={() => handleShowFile(po.d_condiciones_economicas.id, po.id)}
                          />
                        ) : !po.financiacion ? (
                          <PictureAsPdfIcon
                            className={
                              docsReaded.includes(po.id) ||
                              PENDING_APPS[po.estado] ||
                              ACCEPTED_APPS[po.estado]
                                ? classes.docCheked
                                : classes.noChecked
                            }
                            onClick={() => handleShowFile(po.d_condiciones_economicas.id, po.id)}
                          />
                        ) : null}
                        <Box ml={1}>
                          <Typography>{formatMessage({ id: 'global.accept' })}</Typography>
                        </Box>
                        <Checkbox
                          disabled={
                            PENDING_APPS[po.estado] ||
                            ACCEPTED_APPS[po.estado] ||
                            !generalConditions
                          }
                          onChange={() => handleCheckPublicOffer(po.id, po.estado, po.financiacion)}
                          checked={
                            ACCEPTED_APPS[po.estado] ||
                            PENDING_APPS[po.estado] ||
                            publicOffersToSend?.includes(po.id)
                          }
                        ></Checkbox>
                        {po.financiacion && po.d_condiciones_economicas?.id ? (
                          <GetAppIcon
                            className={classes.download}
                            onClick={() => handleDownloadFile(po.d_condiciones_economicas.id)}
                          />
                        ) : !po.financiacion ? (
                          <GetAppIcon
                            className={classes.download}
                            onClick={() => handleDownloadFile(po.d_condiciones_economicas.id)}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  ) : null
                )
              ) : (
                <Box alignItems="center" display="flex" ml={1} mt={1} mb={1}>
                  {formatMessage({
                    id: 'pages.profile.company.guild.info.publicOffer.noOffers.market.origin',
                  })}
                </Box>
              )}
            </Box>
          </>
        ))}
      </Box>
    )
  }
)
