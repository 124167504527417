import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useModalContext } from '../../../../ui/modal/contexts'
import { ThemeButton, ConfirmationDialog } from '../../../../ui'
import { useAppsDetail } from '../../hooks'
import { CancelAppDialog } from '../dialogs'
import { CANT_PASS_TO_GUILD_STATES, CANT_CANCEL_APPLICATION_STATES } from '../../constants'
import { CommunicationsBtn } from '../communications'

export const HeaderRightBtns = ({ application, setApplication }) => {
  const { formatMessage } = useIntl()
  const { role } = useSelector((state) => state.global)
  const {
    actions: { open },
  } = useModalContext()
  const { getAppById, cancelApplication, appJoinGuild } = useAppsDetail()

  const canUpdateApplication =
    (role === 'gremio' && application.gremio_id) || (role !== 'gremio' && !application.gremio_id)

  const notPermittedState = CANT_PASS_TO_GUILD_STATES[application.estado]

  const canPassApplicationToGuild =
    !application.gremio_id &&
    application?.empresa_ca?.gremio_id &&
    application?.empresa_ca?.aceptacion_gremio &&
    !notPermittedState
  const updateApplication = () => {
    getAppById(application.id).then(({ data }) => {
      setApplication(data)
    })
    //refresh bitacora if needed
  }

  const handleOpenCancelModal = () => {
    open({
      Component: CancelAppDialog,
      data: {
        estadoSolicitud: application['estado'],
        id: application['id'],
        cancelApplication,
        updateApplication,
      },
      type: 'centered',
    })
  }

  const assignAppToAssociation = () => {
    appJoinGuild(application.id, application.empresa_ca.gremio_id)
      .then(({ data }) => {
        setApplication(data)
      })
      .catch(() => console.debug('error in application joining guild'))
  }

  const handleAssignToAssociation = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.application.view.documentRequestAssociation.title' }),
        text: formatMessage({ id: 'pages.application.view.documentRequestAssociation.text' }),
        yesText: formatMessage({ id: 'pages.application.view.documentRequestAssociation.yes' }),
        noText: formatMessage({ id: 'pages.application.view.documentRequestAssociation.no' }),
        yesAction: assignAppToAssociation,
      },
      type: 'centered',
    })
  }
  return (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={2}>
        <CommunicationsBtn {...{ application }} />
      </Box>
      {canUpdateApplication && !CANT_CANCEL_APPLICATION_STATES[application['estado']] && (
        <ThemeButton color="default" size="small" onClick={handleOpenCancelModal}>
          {formatMessage({ id: 'pages.application.detail.actionButtons.cancelApp' })}
        </ThemeButton>
      )}
      {canPassApplicationToGuild ? (
        <Box ml={2}>
          <ThemeButton color="primary" size="small" onClick={handleAssignToAssociation}>
            {formatMessage({ id: 'pages.application.detail.actionButtons.asociarSolicitud' })}
          </ThemeButton>
        </Box>
      ) : null}
    </Box>
  )
}
