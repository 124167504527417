import React, { useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import {
  CAMPAIGN_TABLE_ACTIONS,
  CAMPAIGN_TABLE_COLUMNS,
} from '../constants/campaign-table.constants'
// import { TableActions } from '../table-actions'
import { campaignTableStyles } from './campaign-table.styles'
import { TableUrlPagination } from '../../../ui'
// import { ADMIN_ROLES } from '../../../../core/constants'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const CompatibleCampaignTable = ({ campanas, handleChange, formState,pagination }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const role = useSelector((state) => state.global.role)
  const {
    data,
    // search,
    pagination: { total_campanas: totalEntries },
    isLoading,
  } = useSelector((state) => state.applications)
  const combos = useSelector((state) => state.combos)

  const computeDate = (date) => {
    const placeholderDate = new Date(date)
    const formattedDate = placeholderDate.toLocaleDateString('es-ES')
    return formattedDate
  }

  const handleSelectRow = (rows, row) => {
    if (
      formState.camp2 &&
      formState.camp3 &&
      row.id !== formState.camp2.id &&
      row.id !== formState.camp3.id
    ) {
      return
    } else {
      if (!formState.camp2 && !formState.camp3) {
        handleChange({
          target: {
            name: 'camp2',
            value: row,
          },
        })
        return
      }
      if (!formState.camp2 && formState.camp3 && formState.camp3.id !== row.id) {
        handleChange({
          target: {
            name: 'camp2',
            value: row,
          },
        })
        return
      }
      if (formState.camp2 && !formState.camp3 && formState.camp2.id !== row.id) {
        handleChange({
          target: {
            name: 'camp3',
            value: row,
          },
        })
        return
      }

      if (formState.camp2 && row.id === formState.camp2.id) {
        handleChange({
          target: {
            name: 'camp2',
            value: undefined,
          },
        })
        return
      }
      if (formState.camp3 && row.id === formState.camp3.id) {
        handleChange({
          target: {
            name: 'camp3',
            value: undefined,
          },
        })
        return
      }
    }
  }

  const formattedColumns = useMemo(
    () =>
      CAMPAIGN_TABLE_COLUMNS.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.users.table.columns.${column.field}.title` }),
          cellStyle: campaignTableStyles.cell,
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useMemo(() => {
    return campanas.map((item) => {
      return {
        ...item,
        fechainicio: computeDate(item.fechainicio),
        fechafin: computeDate(item.fechafin),
        tableData: {
          ...item.tableData,
        },
      }
    })
  }, [campanas])

  const handleCheckDisabledRow = useCallback(
    (rowData) => {
      let isDisabled = false
      if (formState.camp2 && formState.camp3) {
        isDisabled = true
        if (rowData.id === formState.camp2.id) {
          isDisabled = false
        } else if (rowData.id === formState.camp3.id) {
          isDisabled = false
        }
      } else {
        isDisabled = false
      }
      return {
        disabled: isDisabled,
      }
    },
    [formState.camp2, formState.camp3]
  )

  const tableOptions = {
    toolbar: true,
    selection: true,
    showTitle: false,
    searchFieldStyle: { width: 1100 },
    showTextRowsSelected: false,
    searchFieldAlignment: 'left',
    showSelectAllCheckbox: false,
    tableLayout: 'fixed',
    headerStyle: campaignTableStyles.header,
    draggable: false,
    selectionProps: handleCheckDisabledRow,
  }

  // const tableComponents = useMemo(
  //   () => ({
  //     Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
  //     Cell: (props) => (
  //       <MTableCell {...props} style={{ padding: 0 }}>
  //         {props.columnDef.field === 'active' ? <TableActions {...props} /> : props.children}
  //       </MTableCell>
  //     ),
  //   }),
  //   []
  // )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      options={tableOptions}
      totalEntries={pagination.total_entries}
      // search={search}
      // components={tableComponents}
      //Not transformed in the url table interface:
      data={processedData}
      isLoading={pagination.loading}
      onSelectionChange={handleSelectRow}
      defaultPagination={true}
    />
  )
}
