import React, { useEffect } from 'react'
import { Typography, Box, Grid, CircularProgress } from '@material-ui/core'

import { TextInput, BasicListItem } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'

export const InstCupsBlock = () => {
  const { formFields, formState, getAddressByCUPS } = useInspectionsContext()
  const { addresses, type } = useInspectionsContext()
  const { loadingHomeInfo } = useInspectionsContext()

  const address = addresses.length ? addresses[0] : null

  useEffect(() => {
    if (formState.cups?.length === 20) {
      getAddressByCUPS(formState.cups)
    }
  }, [formState.cups]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid item xs={12}>
        <TextInput {...{ ...formFields.CUPS, disabled: loadingHomeInfo }} />
      </Grid>
      {loadingHomeInfo ? (
        <Grid item xs={12}>
          <Box p={1} display="flex" justifyContent="center">
            <CircularProgress size={20} style={{ marginRight: 15 }} />
            <Typography variant="body2" color="textSecondary">
              Recuperando datos...
            </Typography>
          </Box>
        </Grid>
      ) : null}
      {address ? (
        <Grid item xs={12}>
          <Box p={1} bgcolor="grey.100" borderRadius={6}>
            <BasicListItem
              {...{
                label: 'Titular',
                value: `${address.nombre || ''} ${address.apellido_1 || ''} ${
                  address.apellido_2 || ''
                }`,
              }}
            />
            <BasicListItem
              {...{
                label: 'Dirección',
                value:
                  `${address.tipo_via || ''} ${address.calle || ''} ${
                    address.numero_finca || ''
                  }, ${address.municipio || ''} (${address.cp || ''}), ${address.provincia || ''}` +
                  `${address.piso ? `, Piso: ${address.piso}` : ''}` +
                  `${address.puerta ? `, Puerta: ${address.puerta}` : ''}`,
              }}
            />
            <BasicListItem {...{ label: 'Tipo de instalación', value: address.tipo_instalacion }} />
            <BasicListItem {...{ label: 'Tipo de gas', value: address.tipo_de_gas }} />
            <BasicListItem {...{ label: 'Comercializadora', value: address.comercializadora }} />
            <BasicListItem {...{ label: 'Distribuidora', value: address.sociedad }} />
            {type === 'jca' ? (
              !address.anomalias.length ? (
                <BasicListItem {...{ label: 'Anomalías', value: 'Sin anomalías' }} />
              ) : (
                <>
                  <Typography gutterBottom variant="body1" color="textSecondary">
                    Anomalías:
                  </Typography>
                  <Box pl={2}>
                    {address.anomalias.map((a, i) => (
                      <Typography key={i} variant="body1" gutterBottom>
                        - {a.value}
                      </Typography>
                    ))}
                  </Box>
                </>
              )
            ) : null}
          </Box>
        </Grid>
      ) : null}
    </>
  )
}
