export const ES_CI_HELP_URLS = [
  {
    title: 'Guía de uso de la marca',
    name: 'es_guia_uso',
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Guia_EmpColab_NEDGIA_JUL21_v3.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Guia_EmpColab_NEDGIA_JUL21_v3.pdf',
    },
  },
  {
    title: 'Normas',
    name: 'es_normas',
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Normas-Empresa-colaboradora-de-Nedgia.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Normas-Empresa-colaboradora-de-Nedgia.pdf',
    },
  },
  {
    title: '¿Qué marca uso?',
    name: 'es_que_marca_uso',
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/que-marca-uso.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Normas-Empresa-colaboradora-de-Nedgia.pdf',
    },
  },
]

export const CORPORATE_IDENTITY_FIELDS = {
  LOGO_NEDGIA_ONLINE: {
    name: 'es_logo_nedgia_color_online',
    title: {
      ES: 'Descargar',
      CA: 'Descarregar',
    },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_RGB_POS.jpg',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_RGB_POS.pdf',
    },
  },
  LOGO_NEDGIA_USO_OFFLINE_CMYK: {
    name: 'es_logo_nedgia_color_offline_cmyk',
    title: { ES: 'CMYK', CA: 'CMYK' },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_CMYK_POS.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_CMYK_POS-1.pdf',
    },
  },
  LOGO_NEDGIA_USO_OFFLINE_PANTONE: {
    name: 'es_logo_nedgia_color_offline_pantone',
    title: { ES: 'Pantone', CA: 'Pantone' },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_PANTONE_POS.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_PANTONE_POS-1.pdf',
    },
  },
  ROTULACION_VEHICULOS_COLOR: {
    name: 'es_rotulacion_vehiculos_color',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_CMYK_castellano.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_CMYK_catalan.pdf',
    },
  },
  ROTULACION_VEHICULOS_BLANCO: {
    name: 'es_rotulacion_vehiculos_blanco',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://clicktime.symantec.com/3MHzQXjJfBXVBwTL2gL8bWU6H2?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F07%2FEmp-Colab-Nedgia_monocromo_castellano.pdf',
      CA: 'https://clicktime.symantec.com/3M4KwEDxbQu619z3rqydQsu6H2?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F07%2FEmp-Colab-Nedgia_monocromo_catalan.pdf',
    },
  },
  RRSS_CUADRADO_POS: {
    name: 'es_rrss_cuadrado_positivo',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://clicktime.symantec.com/398dWPpWLScTbEMmgecSnKi6H2?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F07%2FEmpr-Colab-Negdia_cuadrado_POS.png',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/09/Empresa-colaboradora_CAT-cuadrado_POS.png',
    },
  },
  RRSS_CUADRADO_NEG: {
    name: 'es_rrss_cuadrado_negativo',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Empr-Colab-Negdia_cuadrado_NEG.png',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/09/Empresa-colaboradora_CAT-cuadrado_NEG.png',
    },
  },
  RRSS_REDONDO_POS: {
    name: 'es_rrss_redondo_positivo',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://clicktime.symantec.com/3Y9qZRGAXekDVFJaoYEXBkj6H2?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F07%2FEmpr-Colab-Negdia_redondo_POS.png',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/09/Empresa-colaboradora_CAT-redondo_POS.png',
    },
  },
  RRSS_REDONDO_NEG: {
    name: 'es_rrss_redondo_negativo',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Empr-Colab-Negdia_redondo_NEG.png',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/09/Empresa-colaboradora_CAT-redondo_NEG.png',
    },
  },
  PAPELERIA_CMYK: {
    name: 'es_papeleria_cmyk',
    title: { ES: 'CMYK', CA: 'CMYK' },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_CMYK_33mm.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_CMYK_33mm-1.pdf',
    },
  },
  PAPELERIA_PANTONE: {
    name: 'es_papeleria_pantone',
    title: { ES: 'PANTONE', CA: 'PANTONE' },
    url: {
      ES: 'https://clicktime.symantec.com/3GCXamJNAXkZW6RMwSKPxXk6H2?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F07%2FEmp-Colab-Nedgia_PANTONE_33mm.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Emp-Colab-Nedgia_PANTONE_33mm-1.pdf',
    },
  },
  IDENTIFICACION_LOCAL_EXTERIOR_POS: {
    name: 'es_identifiacion_local_exterior_positivo',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://clicktime.symantec.com/3GadB1ZZGdCdJrjMS1UH8Tk6H2?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F07%2FNedgia-Empr-colab_ident-exterior_POS.pdf',
      CA: 'https://clicktime.symantec.com/34r5gDkAZV8u3XfXNe4dxzu6H2?u=https%3A%2F%2Fwww.nedgia.es%2Fcolaboradores%2Fwp-content%2Fuploads%2Fsites%2F4%2F2021%2F07%2FNedgia-Empr-colab_ident-exterior_POS_CAT.pdf',
    },
  },
  IDENTIFICACION_LOCAL_EXTERIOR_NEG: {
    name: 'es_identifiacion_local_exterior_negativo',
    title: { ES: 'Descargar', CA: 'Descarregar' },
    url: {
      ES: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Nedgia-Empr-colab_ident-exterior_NEG.pdf',
      CA: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/Nedgia-Empr-colab_ident-exterior_NEG_CAT.pdf',
    },
  },
}

export const CORPORATE_IDENTITY_STUCTURE = [
  {
    title: 'Logo Nedgia Color (uso online)',
    links: ['LOGO_NEDGIA_ONLINE'],
  },
  {
    title: 'Logo Nedgia Color (uso offline)',
    links: ['LOGO_NEDGIA_USO_OFFLINE_CMYK', 'LOGO_NEDGIA_USO_OFFLINE_PANTONE'],
  },
  {
    title: 'Rotulación Vehiculos (color)',
    links: ['ROTULACION_VEHICULOS_COLOR'],
  },
  {
    title: 'Rotulación Vehiculos (blanco)',
    links: ['ROTULACION_VEHICULOS_BLANCO'],
  },
  {
    title: 'Otros formatos (cuadrado)',
    links: ['RRSS_CUADRADO_POS', 'RRSS_CUADRADO_NEG'],
  },
  {
    title: 'Otros formatos (redondo)',
    links: ['RRSS_REDONDO_POS', 'RRSS_REDONDO_NEG'],
  },
  {
    title: 'Papelería',
    links: ['PAPELERIA_CMYK', 'PAPELERIA_PANTONE'],
  },
  {
    title: 'Identificación local/exterior',
    links: ['IDENTIFICACION_LOCAL_EXTERIOR_POS', 'IDENTIFICACION_LOCAL_EXTERIOR_NEG'],
  },
]
