import React, { useEffect, useState } from 'react'
import { TextField, Typography, Tooltip } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { ListboxComponent } from './virtualized-list-box.component'

const REQUEST_TIMEOUT = 300

export const AutocompleteInput = ({
  name,
  optionKey,
  title,
  placeholder = '',
  requestPath,
  onChange,
  onType,
  values = [],
  required = false,
  disabled = false,
  labelOption,
  inputMinLength = 3,
  tooltipEllipsis = false,
  requestTimeout = REQUEST_TIMEOUT,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (onType && inputValue && inputValue.length >= inputMinLength) {
      setLoading(true)
      const timer = setTimeout(async () => {
        try {
          const currentInputValue = inputValue
          if (inputValue === currentInputValue) {
            await onType(inputValue)
            setLoading(false)
          }
        } catch {
          setLoading(false)
        }
      }, requestTimeout)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderTooltipEllipsisOption = (option) => {
    return (
      <Tooltip
        placement="top-start"
        title={option[optionKey]}
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        <Typography variant="body2">{option[optionKey]}</Typography>
      </Tooltip>
    )
  }

  const renderOption = props.renderOption || ((option) => option[optionKey] || option)
  const optionLabel = labelOption || renderOption

  return (
    <Autocomplete
      {...props}
      id={`${name}-autocomplete-input`}
      size="small"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={values}
      getOptionSelected={(option, value) =>
        option[optionKey] ? option[optionKey] === value[optionKey] : option === value
      }
      getOptionLabel={optionLabel}
      renderOption={tooltipEllipsis ? renderTooltipEllipsisOption : renderOption}
      onChange={(event, value) => onChange({ event, value, name })}
      value={props.value || null}
      disabled={disabled}
      autoSelect
      ListboxComponent={ListboxComponent}
      filterOptions={(options, state) => {
        const { inputValue, getOptionLabel } = state
        const value = inputValue.trim().toLowerCase()
        return options.filter((option) => {
          if (option.special) {
            return true
          }
          return getOptionLabel(option).toLowerCase().includes(value)
        })
      }}
      renderInput={(params) => (
        <>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <TextField
            {...params}
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              required,
            }}
          />
        </>
      )}
      loading={loading}
    />
  )
}
