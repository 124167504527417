export const INST_LIST_ITEMS = (model) => {
  let items = [
    {
      key: 'tipo_instalacion',
      label: 'Tipo de instalación',
      type: 'combo',
      comboId: 'tipo_instalacion_ab',
    },
    {
      key: 'une',
      label: 'UNE',
      type: 'combo',
      comboId: 'une',
    },
  ]
  // if (model.tipo_instalacion === 'IRI') {
  //   const addressItem = {
  //     key: 'direcciones',
  //     label: 'Direcciones',
  //   }
  //   items.splice(2, 0, addressItem)
  // } else {
  // }
  return items
}

export const INSP_LIST_ITEMS_IP = (model) => {
  let items = [
    {
      key: 'instalador',
      label: 'Instalador',
      type: 'combo',
      comboId: 'inspectores',
    },
    {
      key: 'resultado_inspeccion',
      label: 'Resultado inspección',
      type: 'combo',
      comboId: 'resultado_inspeccion',
    },
    {
      key: 'fecha_inspeccion',
      label: 'Fecha inspección',
      type: 'date',
      comboId: 'fecha_inspeccion',
    },
    {
      key: 'certificado',
      label: 'Certificado',
      type: 'file',
    },
  ]
  if (
    model.resultado_inspeccion === '2' ||
    model.resultado_inspeccion === '3' ||
    model.resultado_inspeccion === '4'
  ) {
    const anomaliesItem = {
      key: 'anomalias',
      label: 'Anomalías',
      type: 'combo',
      comboId: 'anomalias',
      multiple: true,
    }
    items.splice(2, 0, anomaliesItem)
    if (model.resultado_inspeccion === '3' || model.resultado_inspeccion === '4') {
      const serviceStateItem = {
        key: 'situacion_servicio',
        label: 'Situación servicio',
        type: 'combo',
        comboId: 'situacion_servicio',
      }
      items.splice(3, 0, serviceStateItem)
    }
  }
  return items
}

export const INSP_LIST_ITEMS_JCA = (model) => {
  let items = [
    {
      key: 'fecha_inspeccion',
      label: 'Fecha JCA',
      type: 'date',
      comboId: 'fecha_inspeccion',
    },
    {
      key: 'justificante',
      label: 'Justificante JCA',
      type: 'file',
    },
  ]
  return items
}

export const BILL_LIST_ITEMS = (model) => {
  let items = [
    {
      key: 'numero_factura',
      label: 'Número factura',
    },
    {
      key: 'fecha_factura',
      label: 'Fecha factura',
      type: 'date',
    },
    {
      key: 'importe',
      label: 'Importe',
      type: 'currency',
      currency: 'EUR',
    },
    {
      key: 'factura',
      label: 'Factura',
      type: 'file',
    },
  ]
  // if (Number(model.importe) !== 0) {
  //   const billDocItem = {
  //     key: 'factura',
  //     label: 'Factura',
  //     type: 'file',
  //   }
  //   items.splice(3, 0, billDocItem)
  // }
  return items
}

export const SUMMARY_HOME_ITEMS = (homeInfo) => [
  // {
  //   label: 'Titular',
  //   value: `${homeInfo.nombre || ''} ${homeInfo.apellido_1 || ''} ${homeInfo.apellido_2 || ''}`,
  // },
  {
    label: 'CUPS',
    value: homeInfo.cups,
  },
  {
    label: 'Dirección',
    value: `${homeInfo.calle || ''} ${homeInfo.numero_finca || ''}, ${homeInfo.municipio || ''} (${
      homeInfo.cp || ''
    }), ${homeInfo.provincia || ''}`,
  },
  // {
  //   label: 'Tipo de instalación',
  //   value: homeInfo.tipo_instalacion,
  // },
  {
    label: 'Tipo de gas',
    value: homeInfo.tipo_de_gas,
  },
  {
    label: 'Comercializadora',
    value: homeInfo.comercializadora,
  },
  {
    label: 'Distribuidora',
    value: homeInfo.sociedad,
  },
]
