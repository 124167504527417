//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const CAMPAIGN_TABLE_COLUMNS = [
  {
    field: 'nombre',
    align: 'left',
    width: 120,
  },
  {
    field: 'codigo',
    align: 'left',
    width: 120,
  },
  {
    field: 'descuento',
    align: 'left',
    width: 120,
  },
  {
    field: 'ingreso',
    align: 'left',
    width: 120,
  },
  {
    field: 'fechainicio',
    align: 'left',
    width: 120,
  },
  {
    field: 'fechafin',
    align: 'left',
    width: 120,
  },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
