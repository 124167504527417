import { useCallback } from 'react'

import { DOCUMENT_ATTACHMENT_CONFIG, postDoc } from '../../documents'
import { useCertificatesCalls } from './certificates-calls.hook'
import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useCertificateLogics = () => {
  const { postCertificate } = useCertificatesCalls()
  const { putById } = requests

  const createCertificate = useCallback((endpoint, data, documentList, fromModify = false) => {
    return new Promise(async (resolve, reject) => {
      let certificateData = {}
      const appPayload = {
        iban: data.iban,
        codigo_financiacion: data.codigo_financiacion,
        titular_iban: data.titular_iban,
      }
      if (fromModify) {
        if (data.applyCampaign) {
          data.financiacion
            ? (certificateData = { ...data, camp1: data.campanas?.campana_financiacion?.codigo })
            : (certificateData = { ...data, camp1: data.codigo })
          delete certificateData.campanas
          certificateData = { ...certificateData, fromModify: fromModify }
          if (data.cod_solicitud_zeus) {
            delete certificateData.camp1
          }
        } else {
          certificateData = { ...data, fromModify: fromModify }
          if (data.camp1) {
            certificateData = { ...certificateData, camp1: data.camp1.codigo }
          }
          if (data.camp2) {
            certificateData = { ...certificateData, camp2: data.camp2.codigo }
          }
          if (data.camp3) {
            certificateData = { ...certificateData, camp3: data.camp3.codigo }
          }
          if (data.cod_solicitud_zeus) {
            delete certificateData.camp1
            delete certificateData.camp2
            delete certificateData.camp3
          }
        }
      } else {
        certificateData = { ...data }
        if (data.camp1) {
          certificateData = { ...certificateData, camp1: data.camp1.codigo }
        }
        if (data.camp2) {
          certificateData = { ...certificateData, camp2: data.camp2.codigo }
        }
        if (data.camp3) {
          certificateData = { ...certificateData, camp3: data.camp3.codigo }
        }
        if (data.cod_solicitud_zeus) {
          delete certificateData.camp1
          delete certificateData.camp2
          delete certificateData.camp3
        }
      }

      try {
        await Promise.all(
          documentList.map(async (file) => {
            const doc = data[file]
            if (!doc) {
              return
            }
            const payload = {
              tipo: DOCUMENT_ATTACHMENT_CONFIG[file].type,
              documento: doc.data.split(',')[1],
              nombre: doc.name.replace(/\.[^/.]+$/, ''),
              extension: doc.type,
              cliente_id: parseInt(data.client_id),
              solicitud_id: '' + data.solicitud_id,
            }
            if (data.presupuesto_id) {
              payload['presupuesto_id'] = '' + data.presupuesto_id
            }
            const documentResponse = await postDoc(payload)
            certificateData['d_' + file + '_id'] = documentResponse.id
            appPayload['d_' + file + '_id'] = documentResponse.id
            //... and, to reduce charge to the petition,
            certificateData[file] = null
            return
          })
        ).catch(() => {
          throw new Error('doc_failed')
        })

        const answer = await postCertificate(endpoint, certificateData)
          .then(() => {
            if (fromModify) {
              return new Promise((resolve, reject) => {
                putById('/solicitudes', data.solicitud_id, appPayload)
                  .then(({ data }) => resolve(data))
                  .catch(({ response }) => {
                    feedback('error', 'No se ha podido actualizar la solicitud')
                  })
              })
            }
          })
          .catch(() => {
            throw new Error('certificate_failed')
          })
        resolve(answer)
      } catch (err) {
        //TODO: Rollback
        reject(err)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    createCertificate,
  }
}
