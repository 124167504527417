export const getAnomaliesComboByResult = ({ type, address, formState, combos }) => {
  let anomalies = []
  if (type === 'ip') {
    switch (address.resultado_inspeccion) {
      case 'Con anomalías secundarias':
        if (formState.tipo_instalacion === 'IRI') {
          if (address.tipo_instalacion === 'IRI (DOMESTICO >= 70KW)') {
            anomalies = combos.anomalias_secundarias_iri_gran_potencia?.data || []
          } else {
            anomalies = combos.anomalias_secundarias_iri?.data || []
          }
        } else if (formState.tipo_instalacion === 'IRC') {
          anomalies = combos.anomalias_secundarias_irc?.data || []
        } else {
          anomalies = combos.anomalias_secundarias_ind?.data || []
        }
        anomalies = anomalies.map((a) => ({ ...a, isSecondary: true }))
        break
      case 'Con anomalías principales':
      case 'No deja cortar':
        if (formState.tipo_instalacion === 'IRI') {
          if (address.tipo_instalacion === 'IRI (DOMESTICO >= 70KW)') {
            anomalies = combos.anomalias_principales_iri_gran_potencia?.data || []
          } else {
            anomalies = combos.anomalias_principales_iri?.data || []
          }
        } else if (formState.tipo_instalacion === 'IRC') {
          anomalies = combos.anomalias_principales_irc?.data || []
        } else {
          anomalies = combos.anomalias_principales_ind?.data || []
        }
        anomalies = anomalies.map((a) => ({ ...a, isPrimary: true }))
        break
      case 'Con anomalías secundarias y principales':
        if (formState.tipo_instalacion === 'IRI') {
          if (address.tipo_instalacion === 'IRI (DOMESTICO >= 70KW)') {
            anomalies =
              combos.anomalias_secundarias_iri_gran_potencia &&
              combos.anomalias_principales_iri_gran_potencia
                ? [
                    ...combos.anomalias_secundarias_iri_gran_potencia.data.map((a) => ({
                      ...a,
                      isSecondary: true,
                    })),
                    ...combos.anomalias_principales_iri_gran_potencia.data.map((a) => ({
                      ...a,
                      isPrimary: true,
                    })),
                  ]
                : []
          } else {
            anomalies =
              combos.anomalias_secundarias_iri && combos.anomalias_principales_iri
                ? [
                    ...combos.anomalias_secundarias_iri.data.map((a) => ({
                      ...a,
                      isSecondary: true,
                    })),
                    ...combos.anomalias_principales_iri.data.map((a) => ({
                      ...a,
                      isPrimary: true,
                    })),
                  ]
                : []
          }
        } else if (formState.tipo_instalacion === 'IRC') {
          anomalies =
            combos.anomalias_secundarias_irc && combos.anomalias_principales_irc
              ? [
                  ...combos.anomalias_secundarias_irc.data.map((a) => ({
                    ...a,
                    isSecondary: true,
                  })),
                  ...combos.anomalias_principales_irc.data.map((a) => ({
                    ...a,
                    isPrimary: true,
                  })),
                ]
              : []
        } else {
          anomalies =
            combos.anomalias_secundarias_ind && combos.anomalias_principales_ind
              ? [
                  ...combos.anomalias_secundarias_ind.data.map((a) => ({
                    ...a,
                    isSecondary: true,
                  })),
                  ...combos.anomalias_principales_ind.data.map((a) => ({
                    ...a,
                    isPrimary: true,
                  })),
                ]
              : []
        }
        break
      default:
        anomalies = []
    }
  } else {
    anomalies = address.anomalias
  }
  return anomalies
}
